import { Button, Spin, Table } from "antd";
import React from "react";
import { Heading } from "../../../components/Heading";
import { useNavigateSearch } from "../../../hooks/userNavigateSearch";
import { ComplaintLevelEnum } from "../../../models/ComplaintLevelEnum";
import { ComplaintSearchOrderByEnum } from "../../../models/ComplaintSearchOrderByEnum";
import { api, getOrganizationId } from "../../../services/api";
import { localizer } from "../../../services/localizer";
import { getQueryOrderingNumber } from "../../../services/tableHelper";
import { ComplaintDetailModal } from "../ComplaintDetailModal";
import styles from "./styles.module.css";

export function ComplaintList() {

  const navigateSearch = useNavigateSearch();

  const [pageNumber, setPageNumber] = React.useState(1);
  const [complaintListTotal, setComplaintListTotal] = React.useState(0);
  const [complaintList, setComplaintList] = React.useState([]);
  const [tableSorter, setTableSorter] = React.useState({} as any);
  const [isLoading, setIsLoading] = React.useState(false);

  const [complaintId, setComplaintId] = React.useState('');
  const [modalVisible, setModalVisible] = React.useState(false);

  const columns = [
    {
      title: localizer('Whistle blower'),
      dataIndex: 'reportedByUserName'
    },
    {
      title: localizer('Accused'),
      dataIndex: 'againstUserName'
    },
    {
      title: localizer('Accusation Level'),
      dataIndex: 'complaintLevel',
      render: (level: number) => localizer(ComplaintLevelEnum[level])
    },
    {
      title: localizer('Accusation'),
      dataIndex: 'description',
      render: (description: string, record: any) => {
        return (<>
          <span>{description.substring(0, 50)}</span>
          <Button
            onClick={() => {
              setComplaintId(record.id)
              setModalVisible(true)
            }}
            className="ant-btn ant-btn-link ant-btn-sm">
            ...{localizer("see more")}
          </Button>
        </>
        )
      }
    },
    {
      title: localizer('Mentorship'),
      dataIndex: 'partnershipId',
      key: 'mentorship',
      render: (partnershipId: string) =>
        <Button ghost type="default"
          onClick={() => {
            navigateSearch('/mentorship/detail', { mentorshipId: partnershipId })
          }}>
          {localizer("View Mentorship")}
        </Button>,
    },
  ];


  React.useEffect(() => {
    loadComplaintList();
  }, [pageNumber, tableSorter]);

  function loadComplaintList() {

    const orderingNumber = getQueryOrderingNumber(
      ComplaintSearchOrderByEnum,
      ComplaintSearchOrderByEnum.ReportedByUserNameAsc,
      tableSorter
    );

    const params = {
      complaintSearchOrderByEnum: ComplaintSearchOrderByEnum.CreatedDateDesc,
      PageNumber: pageNumber,
      PageSize: 10,
    };

    setIsLoading(true);
    api.get(`/Complaint/${getOrganizationId()}/GetComplaintsByOrganization`, { params }).then((response) => {
      setComplaintList(response.data.complaintDtoList);
      setComplaintListTotal(response.data.complaintListTotal);
    })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }

  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Complaints")}>
      </Heading >
      <div className={styles.halfBackground}></div>
      <div className={styles.mainContent}>
        {<Spin spinning={isLoading} size="large">
          <Table pagination={{
            total: complaintListTotal,
            current: pageNumber,
            showSizeChanger: false,
            onChange: (page) => { setPageNumber(page) }
          }}
            rowKey={'id'} bordered dataSource={complaintList}
            onChange={(_, __, sorter) => {
              setTableSorter(sorter);
            }} columns={columns}
            scroll={{ x: true }}
          />
        </Spin>}
      </div>
      <ComplaintDetailModal
        visible={modalVisible}
        complaintId={complaintId}
        onCancel={() => setModalVisible(false)}
        onFinishModal={() => setModalVisible(false)}
      />
    </div>
  );
}