import { Button, Form, Input, message, Spin, Table, Tooltip } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import React from "react";
import { Heading } from "../../../components/Heading";
import { localizer } from "../../../services/localizer";
import styles from "./styles.module.css";
import { api, getOrganizationId } from "../../../services/api";
import { ClassCreateModal } from "../ClassCreateModal";

export function ClassList() {

  const [classList, setClassList] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [classListTotal, setClassListTotal] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [organizationClassId, setOrganizationClassId] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [isCreatingClassList, setIsCreatingClassList] = React.useState(false);


  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const columns = [
    {
      title: localizer('Name'),
      dataIndex: 'name',
    },
    {
      title: localizer('Teachers'),
      dataIndex: 'teachers',
      render: (teacherList: any[]) => {
        return teacherList.map((teacher: any) =>
          `${teacher.user.firstName} ${teacher.user.lastName}`).join(', ');
      }
    },
    {
      title: localizer('Action'),
      dataIndex: 'id',
      key: 'action',
      render: (idValue: string) =>
        <Button ghost type="default"
          onClick={() => {
            setOrganizationClassId(Number(idValue))
            setModalVisible(true)
          }}>
          {localizer("Edit")}
        </Button>,
    },
  ];

  React.useEffect(() => {
    loadClassList();
  }, [pageNumber, search])

  async function loadClassList() {
    const params = {
      classSearchOrderByEnum: 3,
      pageNumber: pageNumber,
      pageSize: 10,
      classNameSearch: search
    };

    setIsLoading(true);
    try {

      const response = await api.get(`/OrganizationClass/${getOrganizationId()}/GetOrganizationClassesByOrganization`, { params });
      setClassList(response.data.organizationClassListDto);
      setClassListTotal(response.data.organizationClassListTotal);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function sendCreateClassListRequest(csvFile: File) {
    setIsCreatingClassList(true);

    let formData = new FormData();
    formData.append('File', csvFile);
    formData.append('OrganizationId', getOrganizationId() as string);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    api.post("/OrganizationClass/UpsertByCsv", formData, config)
      .then(() => {
        message.success({
          content: localizer("organization class list created successfully"),
          duration: 5, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        });
  
        loadClassList();
      })
      .catch((error) => {
        console.error(error);
        message.error({
          content: localizer("Internal server error"),
          duration: 6, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        });
      })
      .finally(() => {
        setIsCreatingClassList(false)
        inputFileRef.current!.value = '';
      });

  }

  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Classes")}>
        <div className={styles.headingChildren}>
          <div>
            <Form layout="inline">
              <input ref={inputFileRef} type="file" id="fileCsv"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (!e.target.files?.length) {
                    return;
                  }

                  const file = e.target.files[0];
                  sendCreateClassListRequest(file);
                }} />
              <Form.Item className={styles.formItemSearch}>
                <Input className={styles.inputSearch} size="large"
                  placeholder={localizer("Search class")}
                  onChange={(e) => setSearch(e.target.value)}
                  prefix={<SearchOutlined />} />
              </Form.Item>
              <Button onClick={() => {
                setModalVisible(true)
                setOrganizationClassId(0);
              }}
                type="link" size="large">
                + {localizer("Add class").toUpperCase()}
              </Button>
              <Button loading={isCreatingClassList} type="default" size="large"
                onClick={() => {
                  inputFileRef.current!.click();
                }}>
                {localizer("Add list of classes (CSV)").toUpperCase()}
              </Button>
              <Tooltip placement="topLeft" title={localizer("The file must contain the following columns in sequence and without a header: ClassName, TeacherEmail|TeacherEmail|TeacherEmail... Example: Class1, professor1@gmail.com | professor2@gmail.com | professor3@gmail.com...")} >
                <Button type="default" size="large">
                 (?)
                </Button>
              </Tooltip>
            </Form>
          </div>
        </div>
      </Heading >
      <div className={styles.halfBackground}></div>
      <div className={styles.mainContent}>
        <Spin spinning={isLoading} size="large">
          <Table
            pagination={{
              total: classListTotal,
              current: pageNumber,
              onChange: (page) => { setPageNumber(page) }
            }}
            rowKey={'id'}
            bordered
            dataSource={classList}
            columns={columns}
            scroll={{x:true}}
          />
        </Spin>
      </div>
      <ClassCreateModal visible={modalVisible}
        organizationClassId={organizationClassId}
        onCancel={() => {
          setModalVisible(false)
        }}
        onFinishModalForm={async () => {
          setModalVisible(false)
          await loadClassList();
        }} />
    </div >
  );
}
