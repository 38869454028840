import styles from './styles.module.css';
import { Button } from 'antd';
import { StarFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { localizer } from '../../../../services/localizer';

type PreviewAreaStudentCardProps = {
  withButtons?: boolean,
  withRankNumber?: boolean,
  student?: {
    id: number,
    user?: {
      firstName: string,
      lastName: string,
      email: string,
      profileImageBase64: string,
      talentCoins: number
    },
    classes: [
      {
        organizationClass: {
          name: string
        }
      }
    ]
  },
  index?: number,
  recommendButtonClick: () => void
}

export function PreviewAreaStudentCard({ withButtons = true,
  withRankNumber = true,
  student, index = 0, recommendButtonClick }: PreviewAreaStudentCardProps) {

  const navigate = useNavigate();

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.studentData}>
        {
          withRankNumber &&
          <div className={styles.rankNumber}>
            {index}°
          </div>
        }

        <div className={styles.avatar}>
          {student?.user?.profileImageBase64
            ? <img
              src={student?.user?.profileImageBase64}
              alt="avatar" />
            : <UserOutlined style={{ fontSize: '3rem' }} />
          }

        </div>
        <div>
          <div>
            <div className={styles.studentName}>{`${student?.user?.firstName} ${student?.user?.lastName}`}</div>
            <div className={styles.studentEmail}>{student?.user?.email}</div>
          </div>
          <div className={styles.studentGradeCountry}>
            {
              Boolean(student?.classes?.length) &&
              <span className={styles.studentGrade}>
                {student?.classes[0].organizationClass.name}
              </span>
            }
            <div>
              <span className={styles.primaryText}>
                <StarFilled /> {student?.user?.talentCoins ?? 0}
              </span>
            </div>
          </div>
        </div>
      </div>
      {
        withButtons &&
        <div className={styles.buttonGroup}>
          <Button ghost type="default" onClick={() => navigate(`/student/detail/${student?.id}`)}>{localizer("View")}</Button>
          <Button onClick={() => recommendButtonClick()} type="primary">{localizer("Recommend")}</Button>
        </div>
      }
    </div >
  );
}