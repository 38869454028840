import styles from './styles.module.css';
import { localizer } from '../../../../services/localizer';
import { Link } from 'react-router-dom';

export function PreviewAreaCardGroup({ children, title, seeAllRoute }: any) {
  
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.topContent}>
        <div className={styles.previewTitle}>{title} </div>
      </div>
      <div className={styles.previewAreaStudentCardGroup}>
        {children}
      </div>
      <div className={styles.link}>
        <Link to={seeAllRoute} >{localizer("see all").toUpperCase()}</Link>
      </div>
    </div>
  );
}