import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppLayout } from './layouts/AppLayout';
import { Dashboard } from './pages/Dashboard';
import { AppPublicLayout } from './layouts/AppPublicLayout';
import { SubjectDetail } from './pages/Subject/SubjectDetail';
import Login from './pages/Login';
import RootApp from './RootApp';
import { StudentList } from './pages/Student/StudentList';
import { SubjectList } from './pages/Subject/SubjectList';
import { ClassList } from './pages/Class/ClassList';
import { MentorshipList } from './pages/Mentorship/MentorshipList';
import { RecommendationList } from './pages/Recommendation/RecommendationList';
import { StudentDetail } from './pages/Student/StudentDetail';
import { MentorshipDetail } from "./pages/Mentorship/MentorshipDetail";
import { LearningSessionDetail } from "./pages/LearningSession/LearningSessionDetail";
import { ComplaintList } from "./pages/Complaint/ComplaintList";

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootApp />}>
          <Route path="/" element={<AppLayout />} >
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="subject-detail/:id" element={<SubjectDetail />} />
            <Route path="student/list" element={<StudentList />} />
            <Route path="student/detail/:id" element={<StudentDetail />} />
            <Route path="subject/list" element={<SubjectList />} />
            <Route path="class/list" element={<ClassList />} />
            <Route path="mentorship/list" element={<MentorshipList />} />
            <Route path="mentorship/detail" element={<MentorshipDetail />} />
            <Route path="learning-session/detail/:id" element={<LearningSessionDetail />} />
            <Route path="recommendation/list" element={<RecommendationList />} />
            <Route path="complaint/list" element={<ComplaintList />} />
          </Route>
          <Route path="/login" element={<AppPublicLayout />} >
            <Route index element={<Login />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}