import styles from './styles.module.css';
import { localizer } from '../../services/localizer';
import logoFooter from '../../assets/logo-footer.svg';
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
// import GoogleAppleStoreLogo from '../../assets/google-apple-store-logo.png';

export function Footer() {
  return (
    <footer className={styles.contentWrapper}>
      <div className={styles.footerData}>
        <div>
          <img src={logoFooter} alt="Logo" />
        </div>
        <div>
          <ul>
            <li>{localizer("Support contact")}</li>
            <li><a href="mailto:contact@mentorius.app">contact@mentorius.app</a></li>
          </ul>
        </div>
        <div>
          {localizer("Have Fun as You Learn and")}
          < br />
          {localizer("Find Purpose in Learning")}
          < br />
          {localizer("as You Serve Others")}
        </div>
        {/*<div>*/}
        {/*  <img src={GoogleAppleStoreLogo} alt="Logo of Apple and Google app stores" />*/}
        {/*</div>*/}
      </div>
      <div className={styles.copyrightContainer} >
        <div className={styles.footerCopyright}>
          <span className={styles.textCopyright}>
            {`Copyright © ${new Date().getFullYear()} Mentorius. ${localizer("All rights reserved.")}`}
          </span>
        </div>
        <div className={styles.footerSocial}>
          <a href="https://www.instagram.com/mentoriusapp/" target="_blank"><FaInstagram size="24" color="#FFFFFF" /></a>
          <a href="https://www.facebook.com/mentoriusapp" target="_blank"><FaFacebookF size="20" color="#FFFFFF" /></a>
          <a href="https://www.youtube.com/@MentoriusApp" target="_blank"><FaYoutube size="24" color="#FFFFFF" /></a>
        </div>
      </div>
    </footer>
  );
}
