import { Button, Form, Space, Spin, Table, message, Tooltip } from "antd";
import React from "react";
import { Heading } from "../../../components/Heading";
import { localizer } from "../../../services/localizer";
import styles from "./styles.module.css";
import { api, getOrganizationId } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { CheckOutlined } from '@ant-design/icons'
import { SubjectCreateModal } from "../SubjectCreateModal";

export function SubjectList() {
  const navigate = useNavigate();

  const [subjectList, setSubjectList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [subjectId, setSubjectId] = React.useState(0);

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const columns = [
    {
      title: localizer('Name'),
      dataIndex: 'name'
    },
    {
      title: localizer('Code'),
      dataIndex: 'code'
    },
    {
      title: localizer('Active'),
      dataIndex: 'active',
      render: (activeValue: boolean) => activeValue && <CheckOutlined style={{ color: '#4cbb17' }} />
    },
    {
      title: localizer('Action'),
      dataIndex: 'id',
      key: 'action',
      render: (idValue: string) =>
        <Space>
          <Button ghost type="default"
            onClick={() => navigate(`/subject-detail/${idValue}`)}>
            {localizer("View")}
          </Button>
          <Button ghost type="default"
            onClick={() => {
              setSubjectId(Number(idValue));
              setModalVisible(true);
            }}>
            {localizer("Edit")}
          </Button>
        </Space>
    },
  ];

  React.useEffect(() => {
    loadSubjectList();
  }, [])

  async function loadSubjectList() {
    setIsLoading(true);
    try {
      const response = await api.get(`/Subject/GetAll/${getOrganizationId()}`);
      setSubjectList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  }


  function sendCreateSubjectListRequest(csvFile: File) {
    const formData = new FormData();
    formData.append('File', csvFile);
    formData.append('OrganizationId', getOrganizationId() as string);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    api.post("/Subject/CreateOrUpdateSubjectCSV", formData, config)
      .then(() => {
        message.success({
          content: localizer("Subject list created successfully"),
          duration: 5, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        });
  
        loadSubjectList();
      })
      .catch((error) => {
        message.error({
          content: "Internal server error",
          duration: 6, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        });
      })
      .finally(() => {
        inputFileRef.current!.value = '';
      });
  }


  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Subjects")}>
        <div className={styles.headingChildren}>
          <div>
          <Form layout="inline">
              <input ref={inputFileRef} type="file" id="fileCsv" style={{ display: 'none' }}
                onChange={(e) => {
                  if (!e.target.files?.length) {
                    return;
                  }
                  const file = e.target.files[0];
                  sendCreateSubjectListRequest(file);
                }} />
            <Button onClick={() => {setSubjectId(0); setModalVisible(true);}}
              type="link" size="large"> + {localizer("Add subject").toUpperCase()}
            </Button>           
            <Button type="default" size="large"
              onClick={() => 
              inputFileRef.current!.click()
              }>
              {localizer("Add list of subjects (CSV)").toUpperCase()}
            </Button>
            <Tooltip placement="topLeft" title={localizer("The file must contain the following columns in sequence and without a header: name, description, code. Example: Math, Math calculations, MTH.")} >
                <Button type="default" size="large">
                 (?)
                </Button>
            </Tooltip>
          </Form>
          </div>
        </div>
      </Heading >
      <div className={styles.halfBackground}></div>
      <div className={styles.mainContent}>
        <Spin spinning={isLoading} size="large">
          <Table rowKey={'id'} bordered dataSource={subjectList} columns={columns}
            scroll={{ x: true }}
          />
        </Spin>
      </div>
      <SubjectCreateModal visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        subjectId={subjectId}
        onFinishModalForm={async () => {
          setModalVisible(false)
          await loadSubjectList();
        }}
        scroll={{ x: true }}
      />

    </div >
  );
}