import React, { useState } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { api, getOrganizationId, getUserId } from '../../../services/api';
import { localizer } from '../../../services/localizer';
import { PartnershipRecommendationStatus } from '../../../models/PartnershipRecommendationStatus';

type RecommendationCreateModalProps = {
  visible: boolean,
  onCancel: () => void,
  onFinishModalForm: () => void
}

export function RecommendationCreateModal(props: RecommendationCreateModalProps) {
  const [mentor, setMentor] = useState('');
  const [apprentice, setApprentice] = useState('');
  const [subject, setSubject] = useState(0);
  const [comment, setComment] = useState('');
  const [options, setOptions] = useState([]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [isSelectLoading, setIsSelectLoading] = useState(false);


  const [modalForm] = useForm();
  const { TextArea } = Input
  const { Option } = Select

  React.useEffect(() => {
    setIsSelectLoading(true)
    api.get(`/Subject/GetAll/${getOrganizationId()}`).then((response) => {
      setSubjectList(response.data);
    }).catch((error) => console.error(error))
      .finally(() => setIsSelectLoading(false));
  }, [])

  const handleOnFinishForm = () => {
    setConfirmLoading(true);

    const payload = {
      studentMentorId: mentor,
      studentApprenticeId: apprentice,
      subjectId: subject,
      partnershipRecommendationStatus: PartnershipRecommendationStatus['Partnership recommended by teacher'],
      creatorComment: comment,
      organizationId: getOrganizationId()
    }

    const creatorUserId = getUserId();

    api.post(`/Partnership/${creatorUserId}/CreatePartnershipRecommendation`, payload)
      .then(() => {
        props.onFinishModalForm();
        message.success({
          content: localizer("recommendation created successfully"),
          duration: 5, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        });
      }).catch((error) => {
        console.error(error);
        message.error({
          content: localizer("Error creating recommendation"),
          duration: 6, 
          style: {
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        })
      }).finally(() =>
        setConfirmLoading(false)
      );
  }

  function onSearch(studentName: string) {
    const params = {
      studentName: studentName,
      organizationId: getOrganizationId()
    }

    studentName &&
      api.get(`/Student/StudentNamesIds`, { params })
        .then((response) => {
          const options = response.data.map((option: any) => { return <Option value={option.id} key={option.id}>{option.name}</Option> });
          setOptions(options)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
        })
  }

  const onSelectMentor = (data: any) => {
    setMentor(data.value);
    setOptions([]);
  };

  const onSelectApprentice = (data: any) => {
    setApprentice(data.value);
    setOptions([]);
  };

  return (
    <Modal
      title={localizer("Add recommendation")}
      onOk={() => modalForm.submit()}
      visible={props.visible}
      confirmLoading={confirmLoading}
      width={600}
      destroyOnClose={true}
      onCancel={props.onCancel}
    >
      <Form
        form={modalForm} name="basic" preserve={false}
        initialValues={{ remember: true }}
        autoComplete="off" onFinish={handleOnFinishForm}
      >
        <Form.Item
          name="mentor"
          rules={[{ required: true, message: localizer('Please input the mentor.') }]}
        >
          <Select
            showSearch
            labelInValue
            placeholder={localizer("Mentor")}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={onSearch}
            onChange={onSelectMentor}
            notFoundContent={null}
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          name="apprentice"
          rules={[{ required: true, message: localizer('Please input the apprentice.') }]}
        >
          <Select
            showSearch
            labelInValue
            placeholder={localizer("Apprentice")}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={onSearch}
            onChange={onSelectApprentice}
            notFoundContent={null}
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          name="subject"
          rules={[{ required: true, message: localizer('Please input the subject.') }]}
        >
          <Select loading={isSelectLoading} placeholder={localizer("Subject")}
            options={subjectList.map((x: any) => ({ label: x.name, value: x.id }))}
            onChange={(value: number) => setSubject(value)} />
        </Form.Item>
        <Form.Item
          name="comment"
        >
          <TextArea rows={3} placeholder={localizer("Comment")}
            onChange={(e) => setComment(e.target.value)} value={comment} />
        </Form.Item>
      </Form>
    </Modal>
  )
}