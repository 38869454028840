import { Button, Form, Input, Spin, Pagination, message, Select, Tooltip } from "antd";
import React from "react";
import { Heading } from "../../../components/Heading";
import { localizer } from "../../../services/localizer";
import styles from "./styles.module.css";
import { SearchOutlined, DownOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { PreviewAreaStudentCard } from "../../Dashboard/components/PreviewAreaStudentCard";
import { api, getOrganizationId } from "../../../services/api";
import { StudentCreateModal } from "../StudentCreateModal";
import { RecommendationCreateModal } from "../../Recommendation/RecommendationCreateModal";
import { StudentSearchOrderByEnum } from "../../../models/StudentSearchOrderByEnum";

export function StudentList() {
  const [studentList, setStudentList] = React.useState([]);
  const [studentListTotal, setStudentListTotal] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [visibleCreateStudentModal, setVisibleCreateStudentModal] = React.useState(false);
  const [recommendationModalVisible, setRecommendationModalVisible] = React.useState(false);
  const [isCreatingStudentList, setIsCreatingStudentList] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [classList, setClassList] = React.useState([] as any[]);
  const [selectedClass, setSelectedClass] = React.useState<any>(null);
  const [isClassListLoading, setIsClassListLoading] = React.useState(false);
  const [studentListOrder, setStudentListOrder] = React.useState(3);

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    loadClassList()
  }, [])

  async function loadClassList() {
    const params = {
      classSearchOrderByEnum: 3,
      pageNumber: 1,
      pageSize: 100
    };

    setIsClassListLoading(true);
    try {
      const response = await api
        .get(`/OrganizationClass/${getOrganizationId()}/GetOrganizationClassesByOrganization`, { params });

      const selectList = response.data.organizationClassListDto
        .map((x: any) => ({ label: x.name, value: x.id }));

      setClassList([{ label: localizer('Filter by class'), value: null }, ...selectList]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsClassListLoading(false);
    }
  }

  React.useEffect(() => {
    loadStudentList();
  }, [pageNumber, search, studentListOrder, selectedClass])

  const loadStudentList = () => {
    const params = {
      studentSearchOrderByEnum: studentListOrder,
      PageNumber: pageNumber,
      PageSize: 10,
      StudentNameSearch: search,
      OrganizationClassId: selectedClass
    };

    setIsLoading(true)
    api.get(`/Student/${getOrganizationId()}/GetStudentsByOrganization`, { params })
      .then((response) => {
        setStudentList(response.data.studentDtoList);
        setStudentListTotal(response.data.studentListTotal);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }

  function sendCreateStudentListRequest(csvFile: File) {
    setIsCreatingStudentList(true);

    let formData = new FormData();
    formData.append('File', csvFile);
    formData.append('OrganizationId', getOrganizationId() as string);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    api.post("/Student/CreateOrUpdateStudentsCSV", formData, config)
      .then(() => {
        message.success({
          content: localizer("student list created successfully"),
          duration: 6, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        });
  
        loadStudentList();
      })
      .catch((error) => {
        console.error(error);
        message.error({
          content: "Internal server error",
          duration: 6, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        });
      })
      .finally(() => {
        setIsCreatingStudentList(false)
        inputFileRef.current!.value = '';
      });
  }

  function getStudentSortIcon() {
    if (studentListOrder == StudentSearchOrderByEnum.ClassNameAsc)
      return <SortAscendingOutlined />;
    if (studentListOrder == StudentSearchOrderByEnum.ClassNameDesc)
      return <SortDescendingOutlined />;

    return <></>;
  }
  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Students")}>
        <div className={styles.headingChildren}>
          <div>
            <Form layout="inline">
              <input ref={inputFileRef} type="file" id="fileCsv"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (!e.target.files?.length) {
                    return;
                  }

                  const file = e.target.files[0];
                  sendCreateStudentListRequest(file);
                }} />
              <Form.Item className={styles.formItemSearch}>
                <Input className={styles.inputSearch} size="large" onChange={(e) => {
                  const { value } = e.target;
                  if (value.length >= 2 || !value.length) {
                    setSearch(value);
                  }
                }} placeholder={localizer("Search student")} prefix={<SearchOutlined />} />
              </Form.Item>
              <Button type="link" size="large" onClick={() => setVisibleCreateStudentModal(true)}>
                + {localizer("Add student").toUpperCase()}
              </Button>
              <Button loading={isCreatingStudentList} type="default" size="large"
                onClick={() => {
                  inputFileRef.current!.click();
                }}>
                {localizer("Add list of students (CSV)").toUpperCase()}
              </Button>
              <Tooltip placement="topLeft" title={localizer("The file should contain the following columns in sequence and without a header: FirstName,Lastname,Email. Example: Leonardo,Souza,leonardo@email.com")} >
                <Button type="default" size="large">
                 (?)
                </Button>
              </Tooltip>
            </Form>
          </div>
        </div>
      </Heading >
      <div className={styles.halfBackground}></div>
      <div className={styles.mainContent}>
        <Spin spinning={isLoading} size="large">
          <div className={styles.classGroupActions}>
            <Select placeholder={localizer("Filter by class")}
              loading={isClassListLoading}
              suffixIcon={<DownOutlined className={styles.selectArrowIcon} />}
              options={classList}
              onChange={(value) => {
                setSelectedClass(value);
              }}
            />
            <Button ghost type="default" onClick={() => {
              if (studentListOrder == StudentSearchOrderByEnum.NameAsc)
                setStudentListOrder(StudentSearchOrderByEnum.ClassNameAsc)
              else if (studentListOrder == StudentSearchOrderByEnum.ClassNameAsc)
                setStudentListOrder(StudentSearchOrderByEnum.ClassNameDesc)
              else
                setStudentListOrder(StudentSearchOrderByEnum.NameAsc)
            }}>
              {localizer("Order by class")}
              {getStudentSortIcon()}
            </Button>
          </div>
          <div className={styles.listWrapper}>
            {studentList.map((student: any) => {
              return <PreviewAreaStudentCard recommendButtonClick={() => setRecommendationModalVisible(true)} key={student.id} student={student} withRankNumber={false}></PreviewAreaStudentCard>
            })}
          </div>
          <div className={styles.listPagination}>
            <Pagination size="small" current={pageNumber} onChange={(page) => { setPageNumber(page) }} total={studentListTotal} />
          </div>
        </Spin>
      </div>
      <StudentCreateModal
        visible={visibleCreateStudentModal}
        onCancel={() => setVisibleCreateStudentModal(false)}
        onFinishModalForm={() => {
          setVisibleCreateStudentModal(false)
          loadStudentList();
        }} />
      <RecommendationCreateModal
        visible={recommendationModalVisible}
        onCancel={() => setRecommendationModalVisible(false)}
        onFinishModalForm={() => {
          setRecommendationModalVisible(false);
        }}

      />
    </div >
  );
}
