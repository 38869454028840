import { useState } from 'react';
import LoginImage from '../../assets/login-image.png';
import styles from './styles.module.css';
import { localizer } from '../../services/localizer';
import { Form, Input, Button, Spin, message } from 'antd';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

function Login() {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.mainDiv}>
      <div className={styles.image}>
        <img src={LoginImage} alt="login" />
      </div>
      <div className={styles.login}>
        <span className={styles.loginTitle}>{localizer('Log in').toUpperCase()}</span>
        <Spin size='large' delay={500} spinning={isLoading} >
          <div className={styles.loginForm}>
            <Form
              name="loginForm"
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={async () => {
                try {
                  setIsLoading(true);
                  await signInWithEmailAndPassword(auth, email, password);
                } catch (error) {
                  message.error({
                    content: localizer("Wrong email or password"),
                    duration: 3, 
                    style: { 
                      fontSize: '18px', 
                      backgroundColor: 'var(--primary-color)',
                      color: 'var(--secondary-color)',
                    },
                  });
                  setIsLoading(false)
                }
              }}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: localizer('Please input your user email!') }]}
              >
                <Input style={{ borderRadius: '4px' }} placeholder={localizer("Your email")}
                  onChange={(e) => setEmail(e.target.value)} value={email} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: localizer('Please input your password!') }]}
              >
                <Input style={{ borderRadius: '4px' }} type="password" placeholder={localizer("Your password")}
                  onChange={(e) => setPassword(e.target.value)} value={password} />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ borderRadius: '4px', color: '#3B88C3', marginRight: '4%', border: '1px solid #3B88C3', background: 'transparent' }}
                  size="large" className={styles.signUpBtn}>
                  {localizer('Sign-up').toUpperCase()}
                </Button>
                <Button style={{ borderRadius: '4px', backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), border: 'none' }}
                  size="large" type="primary" className={styles.loginBtn} htmlType="submit">
                  {localizer('Login').toUpperCase()}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
        <a href="#" className={styles.loginForgotPassword}>
          {localizer('I forgot my password').toUpperCase()}
        </a>
      </div>
    </div>
  );
}

export default Login;
