import React, { useState } from 'react';
import { Form, Input, message, Modal, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { api, getOrganizationId } from '../../../services/api';
import { localizer } from '../../../services/localizer';

export function StudentCreateModal({ visible, studentId = null, onCancel, onFinishModalForm }: any) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [studentClass, setStudentClass] = useState(null as number | null);

  const [isLoading, setIsLoading] = useState(false);
  const [isReadingDataLoading, setIsReadingDataLoading] = useState(false);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [studentData, setStudentData] = useState(null as any);

  const [modalForm] = useForm();

  React.useEffect(() => {
    if (!visible || !studentId) {
      setName('');
      return;
    }

    setIsReadingDataLoading(true);
    api.get(`/Student/${studentId}`).then((response) => {
      setStudentData(response.data);
      setName([response.data.user.firstName, response.data.user.lastName].join(" "));
      setEmail(response.data.user.email);
      const [firstClass] = response.data.classes;
      setStudentClass(firstClass?.name);
    }).catch(error => console.error(error))
      .finally(() => setIsReadingDataLoading(false));
  }, [visible, studentId])

  React.useEffect(() => {
    const params = {
      classSearchOrderByEnum: 3,
      PageNumber: 1,
      PageSize: 10
    }

    setIsSelectLoading(true)
    api.get(`/OrganizationClass/${getOrganizationId()}/GetOrganizationClassesByOrganization`, { params }).then((response) => {
      setClassList(response.data.organizationClassListDto);
    })
      .catch((error) => console.error(error))
      .finally(() => setIsSelectLoading(false));
  }, [])

  const handleOnFinishForm = async () => {
    setIsLoading(true);
    const nameSplitted = name.split(" ")

    const payload = {
      firstName: nameSplitted.shift(),
      lastName: nameSplitted.join(" "),
      email,
      organizationId: getOrganizationId(),
      studentClassIds: [
        studentClass
      ]
    };

    try {
      if (studentData) {
        const studentUpdateData = { user: studentData.user };
        studentUpdateData.user.firstName = payload.firstName;
        studentUpdateData.user.lastName = payload.lastName;
        await api.put(`/Student/${studentId}`, studentUpdateData)
      } else if (!studentId) {
        await api.post("/Student/CreateStudentAsAdmin", payload)
        message.success({
          content: localizer("Registered student"),
          duration: 5, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
          },
        })
        setName('');
        setEmail('');
        setStudentClass(null);
      }
    } catch (error) {
      console.error(error)
      message.error({
        content: localizer("Internal server error"),
        duration: 6, 
        style: { 
          fontSize: '18px', 
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)'
        },
      })
    } finally {
      setIsLoading(false);
    }

    onFinishModalForm();
  }

  return (
    <Modal
      title={studentId
        ? localizer("Edit student")
        : localizer("Add student")}
      onOk={() => modalForm.submit()}
      open={visible}
      confirmLoading={isLoading}
      width={600}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <Form
        form={modalForm} name="basic" preserve={false}
        initialValues={{ remember: true }}
        autoComplete="off" onFinish={handleOnFinishForm}
      >
        {
          isReadingDataLoading
            ? <Spin />
            : <>
              <Form.Item
                name="name"
                rules={[{
                  required: true,
                  message: localizer('Please input the student name.')
                }]}
                initialValue={name}
              >
                <Input placeholder={localizer("Name")}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
              {(!studentId) &&
                <>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: localizer('Please input the student email.') }]}
                    initialValue={email}
                  >
                    <Input placeholder={localizer("E-mail")}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="class"
                    rules={[{ required: true, message: localizer('Please input the student class.') }]}
                    initialValue={studentClass}
                  >
                    <Select loading={isSelectLoading} placeholder={localizer("Student Class")}
                      options={classList.map((x: any) => ({ label: x.name, value: x.id }))}
                      onChange={(value: number) => setStudentClass(value)} />
                  </Form.Item>
                </>
              }
            </>
        }
      </Form>
    </Modal>
  )
}