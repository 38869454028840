import { Button, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { localizer } from "../../../services/localizer";
import { Step } from "../../Subject/SubjectDetail/components/Step";
import styles from './styles.module.css';


type LearningSessionLearningWorkflowModalProps = {
  visible: boolean,
  learningSessionLearningWorkflowId: string,
  onCancel: () => void,
  onFinishModalForm: () => void,
  subjectName: string,
  mentorName: string,
  apprenticeName: string
}
export function LearningSessionLearningWorkflowModal(props: LearningSessionLearningWorkflowModalProps) {


  const [stepList, setStepList] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState({} as any);
  const [workflow, setWorflow] = useState({} as any);


  useEffect(() => {
    if (props.learningSessionLearningWorkflowId) {
      setIsLoading(true);
      api.get(`/LearningWorkflow/LearningSessionLearningWorkflow/${props.learningSessionLearningWorkflowId}/GetLearningSessionLearningWorkflow`)
        .then(response => {
          setWorflow(response.data);
          setStepList(response.data.learningWorkflowStepList);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (currentStep) {
      setCurrentStepById(currentStep.id);
    }
  }, [stepList]);

  function setCurrentStepById(stepId: number) {
    const step = stepList.find(x => x.id === stepId);
    if (step) {
      setCurrentStep(step);
    }
  }

  return (


    <Modal
      title={localizer("Learning workflow")}
      visible={props.visible}
      width={950}
      destroyOnClose={true}
      onCancel={props.onCancel}
      footer={[
        <Button type="link" key="close" onClick={props.onCancel}>
          {localizer("Close")}
        </Button>
      ]}
    >
      {props.learningSessionLearningWorkflowId &&
        <div className={styles.contentWrapper}>
          <div className={styles.stepList}>

            <div className={styles.heading}>
              <h4>{localizer("Learning workflow")}</h4>
            </div>

            <h2>
              {`${props.mentorName} ${localizer("is teaching")} ${props.subjectName} ${localizer("to")} ${props.apprenticeName}`}
            </h2>

            <p>{workflow.description}</p>

            <Spin spinning={isLoading}>
              {
                stepList.map((step) =>
                  <Step
                    key={step.id}
                    step={step}
                    readonly
                  />
                )
              }
            </Spin>
          </div>
        </div>
      }
    </Modal>
  );
}
