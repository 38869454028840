import { useEffect, useState } from "react";
import { Heading } from "../../../components/Heading";
import { localizer } from "../../../services/localizer";
import { StepList } from "./components/StepList";
import { api } from "../../../services/api";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import styles from "./styles.module.css";


export function SubjectDetail() {


  const { id } = useParams();
  const [description, setDescription] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [learningWorkflowId, setLearningWorkflowId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    api.get(`/subject/${id}`).then(x => {
      setSubjectName(x.data.name);
      setDescription(x.data.description);
      setLearningWorkflowId(x.data.learningWorkflowId);
    })
      .catch((error) => console.error(error));
  }, []);

  async function handleCreateLearningWorkflowClick() {
    setIsLoading(true);
    try {
      const response = await api.post(`/LearningWorkflow/${id}`,
        {
          version: "1.0",
          isActive: true
        });

      setLearningWorkflowId(response.data.id);

    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Heading
        title={subjectName}
        descriptionTitle={localizer("Description")}
        descriptionText={description}
      >
        {!learningWorkflowId &&
          <div className={styles.headingChildren}>
            <div>
              <Button loading={isLoading} type="link" size="large" onClick={handleCreateLearningWorkflowClick}>
                + {localizer("Add learning workflow").toUpperCase()}
              </Button>
            </div>
          </div>
        }
      </Heading>
      <div>
        <StepList learningWorkflowId={learningWorkflowId} />
      </div>
    </>
  );
}