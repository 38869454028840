import { Button, Spin, Table } from "antd";
import React from "react";
import { Heading } from "../../../components/Heading";
import { localizer } from "../../../services/localizer";
import styles from "./styles.module.css";
import { api, getOrganizationId } from "../../../services/api";
import { RecommendationCreateModal } from "../RecommendationCreateModal";
import { PartnershipRecommendationStatus } from "../../../models/PartnershipRecommendationStatus";
import { getQueryOrderingNumber } from "../../../services/tableHelper";
import { RecommendationSearchOrderByEnum } from "../../../models/RecommendationSearchOrderByEnum";

export function RecommendationList() {

  const [pageNumber, setPageNumber] = React.useState(1);
  const [recommendationListTotal, setRecommendationListTotal] = React.useState(0);
  const [recommendationList, setRecommendationList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableSorter, setTableSorter] = React.useState({} as any);

  const [createModalVisible, setCreateModalVisible] = React.useState(false)

  const columns = [
    {
      title: localizer('Mentor'),
      dataIndex: 'mentorName',
      sorter: true
    },
    {
      title: localizer('Apprentice'),
      dataIndex: 'apprenticeName',
      sorter: true
    },
    {
      title: localizer('Subject'),
      dataIndex: 'subjectName',
      sorter: true
    },
    {
      title: localizer('Created by'),
      dataIndex: 'creatorUserName',
      sorter: true
    },
    {
      title: localizer('Created at'),
      dataIndex: 'createdDate',
      sorter: true,
      render: (date: string) => new Date(date).toLocaleString()
    },
    {
      title: localizer('Status'),
      dataIndex: 'partnershipRecommendationStatus',
      sorter: true,
      render: (partnershipRecommendationStatus: number) => {
        return localizer(PartnershipRecommendationStatus[partnershipRecommendationStatus].toString());
      }
    },
  ];

  React.useEffect(() => {
    loadRecommendations();
  }, [pageNumber, tableSorter])

  function loadRecommendations() {

    const orderingNumber = getQueryOrderingNumber(
      RecommendationSearchOrderByEnum,
      RecommendationSearchOrderByEnum.MentorNameAsc,
      tableSorter
    );

    const params = {
      recommendationSearchOrderByEnum: orderingNumber,
      PageNumber: pageNumber,
      PageSize: 10
    }

    setIsLoading(true)
    api.get(`/Partnership/${getOrganizationId()}/GetRecommendationsByOrganization`, { params }).then((response) => {
      setRecommendationList(response.data.partnershipRecommendationListDto);
      setRecommendationListTotal(response.data.partnershipRecommendationListTotal);
    })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }

  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Recommendations")}>
        <div className={styles.headingChildren}>
          <div>
            <Button type="link" size="large"
              onClick={() => setCreateModalVisible(true)}>
              + {localizer("Add recommendation").toUpperCase()}
            </Button>
          </div>
        </div>
      </Heading >
      <div className={styles.halfBackground}></div>
      <div className={styles.mainContent}>
        <Spin spinning={isLoading} size="large">
          <Table pagination={{
            total: recommendationListTotal,
            current: pageNumber,
            showSizeChanger: false,
            onChange: (page) => { setPageNumber(page) }
          }}
            rowKey={'id'} bordered dataSource={recommendationList}
            onChange={(_, __, sorter) => setTableSorter(sorter)} columns={columns}
            scroll={{ x: true }}
          />
        </Spin>
      </div>
      <RecommendationCreateModal
        visible={createModalVisible}
        onCancel={() => setCreateModalVisible(false)}
        onFinishModalForm={() => {
          setCreateModalVisible(false);
          loadRecommendations();
        }} />
    </div >
  );
}