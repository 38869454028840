import logo from '../../assets/logo.svg';
import { FaUser, FaBars } from "react-icons/fa";
import styles from './styles.module.css'
import { Localizer, localizer } from "../../services/localizer";
import { Link, NavLink } from "react-router-dom";
import { getAuth, signOut } from 'firebase/auth';
import { Button, Dropdown, Menu } from "antd";
import Flags from 'country-flag-icons/react/3x2';

export function Header() {

  const customClassName = ({ isActive }: any) =>
    isActive ? styles.current : '';

  const menu = (
    <Menu>
      {/*<Menu.Item key="0">*/}
      {/*  {localizer("My profile").toUpperCase()}*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="1">
        <span className={styles.logoutButton}
          onClick={async () => {
            await signOut(getAuth());
          }}>
          {localizer("Logout").toUpperCase()}
        </span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item className={styles.languageList} key="3">
        <Flags.US onClick={() => Localizer.changeLanguage('en-US')} title='United States' />
        <Flags.BR onClick={() => Localizer.changeLanguage("pt-BR")} title='Brasil' />
        <Flags.ES onClick={() => Localizer.changeLanguage("es-ES")} title='España' />
      </Menu.Item>
    </Menu>
  );

  const navItemList = [
    { name: "Students", route: "/student/list" },
    { name: "Classes", route: "/class/list" },
    { name: "Subjects", route: "/subject/list" },
    { name: "Mentorships", route: "/mentorship/list" },
    { name: "Recommendations", route: "/recommendation/list" },
    { name: "Complaints", route: "/complaint/list" },
  ];

  const mobileMenu = (
    <Menu
      theme="dark"
      mode="vertical"
      className={styles.mobileNavBar}
      disabledOverflow={true}
    >
      {navItemList.map((item, index) => {
        const key = index + 1;
        return <Menu.Item key={key} className={styles.menuItemMobile}>
          <NavLink to={item.route}>
            {localizer(item.name).toUpperCase()}
          </NavLink>
        </Menu.Item>
      })}

    </Menu>
  )

  return (
    <header className={styles.appHeader}>
      <div className={styles.headerNav}>
        <Link to="/">
          <img src={logo} className={styles.appLogo} alt="logo" />
        </Link>

        <Dropdown className={styles.mobileNavBarButton} overlay={mobileMenu}
          trigger={['click']} placement="bottomRight">
          <Button onClick={(e) => e.preventDefault()}><FaBars /></Button>
        </Dropdown>

        <nav className={styles.navBar}>
          {navItemList.map((item, index) => {
            return (
              <NavLink key={index + 1} className={customClassName} to={item.route}>
                {localizer(item.name).toUpperCase()}
              </NavLink>
            )
          })}
        </nav>
      </div>
      <div className={styles.mainMenu}>
        <Dropdown overlay={menu} trigger={['click']}>
          <button className={styles.profileButton}>
            <FaUser size="24" color={getComputedStyle(document.documentElement).getPropertyValue('--primary-color')} />
          </button>
        </Dropdown>
      </div>
    </header >
  );
}
