import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { UploadOutlined, CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Space, Table, Upload } from 'antd';
import { localizer } from '../../../../../services/localizer';
import { Attachment } from '../../../../../models/Attachment';
import { SupportMaterialType } from '../../../../../models/SupportMaterialType';
import { api } from '../../../../../services/api';

type StepAttachmentFormProp = {
    currentAttachmentList: Attachment[],
    onUpdateAttachmentList: any,
    onDeleteAttachment: any
}

export function StepAttachmentForm({ currentAttachmentList = [], onUpdateAttachmentList, onDeleteAttachment }: StepAttachmentFormProp) {

    const { Option } = Select;
    const { confirm } = Modal;

    const [attachmentName, setAttachmentName] = useState('');
    const [attachmentType, setAttachmentType] = useState(SupportMaterialType.Link);
    const [attachmentLink, setAttachmentLink] = useState('');
    const [attachmentFile, setAttachmentFile] = useState({} as File);


    const [attachmentList, setAttachmentList] = useState([] as Attachment[]);

    const [completeUploadInvisible, setCompleteUploadInvisible] = useState(true);
    const [idAttachmentIsDeleting, setIdAttachmentIsDeleting] = useState(0);

    useEffect(() => {
        onUpdateAttachmentList(attachmentList);
    }, [attachmentList])

    const columns = [
        {
            title: localizer('Type'),
            dataIndex: 'supportMaterialType',
            render: (supportMaterialType: number) => { return SupportMaterialType[supportMaterialType] }
        },
        {
            title: localizer('Name'),
            dataIndex: 'name',
        },
        {
            title: localizer('Link'),
            dataIndex: 'link',
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id: number) => {
                return idAttachmentIsDeleting === id
                    ? <SyncOutlined spin={true} />
                    : id && <DeleteOutlined onClick={() => { handleDeleteAttachmentClick(id) }} />
            }
        },
    ]

    function handleDeleteAttachmentClick(supportMaterialId: number) {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: localizer("Are you sure you want to permanently remove this attachment?"),
            okText: localizer("Confirm"),
            onOk() {
                setIdAttachmentIsDeleting(supportMaterialId);
                api.delete(`/LearningWorkflow/LearningWorkflowStepSupportMaterial/${supportMaterialId}`)
                    .then(() => {
                        onDeleteAttachment();
                    })
                    .catch(error => console.error(error))
                    .finally(() => setIdAttachmentIsDeleting(0));
            }
        });
    }

    function handleAddAttachment() {
        if (attachmentName && (attachmentLink || Object.entries(attachmentFile).length > 0)) {
            setAttachmentList([...attachmentList,
            {
                name: attachmentName,
                supportMaterialType: attachmentType,
                link: attachmentLink,
                file: attachmentFile
            }]);
            cleanAttachmentForm();
        }
    }

    function cleanAttachmentForm() {
        setAttachmentName('');
        setAttachmentLink('');
        setAttachmentFile({} as File);
        setCompleteUploadInvisible(true);
    }

    return (
        <>
            <h4 className={styles.attachmentTitle}>{localizer("Attachments")}</h4>

            <Table
                columns={columns}
                dataSource={[...currentAttachmentList, ...attachmentList]}
                pagination={false} size="small"
                locale={{ emptyText: localizer("No data") }}
                style={{ margin: "10px" }}
                rowKey={'name'}
                scroll={{ x: true }}
            />

            <Space className={styles.formAttachment}>
                <Select
                    className={styles.attachmentTypeSelect}
                    value={attachmentType}
                    onChange={(value) => setAttachmentType(value)}
                >
                    {Object.values(SupportMaterialType).filter((x) => Number(x))
                        .map((x) => (
                            <Option value={x}>{localizer(SupportMaterialType[x as SupportMaterialType])}</Option>
                        ))}
                </Select>

                <Input placeholder={localizer("Name")} id="name"
                    value={attachmentName} onChange={(e) => setAttachmentName(e.target.value)}>
                </Input>

                {attachmentType === SupportMaterialType.Link
                    ? <Input placeholder={localizer("Link")}
                        value={attachmentLink} onChange={(e) => setAttachmentLink(e.target.value)}></Input>
                    : ""
                }

                {attachmentType != SupportMaterialType.Link
                    && <Upload beforeUpload={(file) => {
                        setAttachmentFile(file)
                        return false;
                    }} maxCount={1} showUploadList={false}
                        onChange={(info) => {
                            if (info.fileList.length > 0)
                                setCompleteUploadInvisible(false);
                        }}
                    >
                        <Button icon={
                            completeUploadInvisible
                                ? <UploadOutlined />
                                : <CheckOutlined />
                        }>{localizer("Click to Upload")}</Button>
                    </Upload>
                }
                <Button
                    className={styles.addButton}
                    type="primary"
                    onClick={handleAddAttachment}>
                    {localizer("Add")}
                </Button>
            </Space>
        </>
    );
}