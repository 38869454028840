import { useState } from 'react';
import { Form, Input, message, Modal, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { api, getOrganizationId } from '../../../services/api';
import { localizer } from '../../../services/localizer';
import React from 'react';

export function SubjectCreateModal({ visible, subjectId, onCancel, onFinishModalForm }: any) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isReadingDataLoading, setIsReadingDataLoading] = useState(false);

  const [modalForm] = useForm();

  const url = "/Subject";

  React.useEffect(() => {
    if (!visible || !subjectId) {
      setName('');
      setDescription('');
      setCode('');
      return;
    }

    setIsReadingDataLoading(true);
    api.get(`${url}/${subjectId}`).then((response) => {
      setName(response.data.name);
      setDescription(response.data.description);
      setCode(response.data.code);
    }).catch((error) => console.error(error))
      .finally(() => setIsReadingDataLoading(false))
  }, [subjectId, visible])

  const handleOnFinishForm = async () => {
    setIsLoading(true);

    const payload = {
      name,
      description,
      code,
      organizationId: getOrganizationId(),
    };

    try {
      if (subjectId) {
        await api.put(`/Subject/${subjectId}`, payload);
      } else {
        const createSubjectResponse = await api.post("/Subject", payload);
        await api.post(`/LearningWorkflow/${createSubjectResponse.data}`,
          {
            version: "1.0",
            isActive: true
          });
      }
    } catch (error) {
      console.error(error)
      message.error({
        content: localizer("You already have this file in this step"),
        duration: 6, 
        style: { 
          fontSize: '18px', 
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
        },
      });
    } finally {
      setIsLoading(false);
    }

    onFinishModalForm();
  }

  return (
    <Modal
      title={localizer("Add subject")}
      onOk={() => modalForm.submit()}
      visible={visible}
      confirmLoading={isLoading}
      width={600}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <Form
        form={modalForm} name="subjectCreateModal" preserve={false}
        autoComplete="off" onFinish={handleOnFinishForm}
      >
        {
          isReadingDataLoading
            ? <Spin />
            : <>
              <Form.Item
                name="name"
                rules={[{ required: true, message: localizer('Please input the name.') }]}
                initialValue={name}
              >
                <Input placeholder={localizer("Name")}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="description"
                rules={[{ required: true, message: localizer('Please input the description.') }]}
                initialValue={description}
              >
                <Input placeholder={localizer("Description")}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[{ required: true, message: localizer('Please input the code.') }]}
                initialValue={code}
              >
                <Input placeholder={localizer("Code")}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Form.Item>
            </>
        }
      </Form>
    </Modal>
  )
}