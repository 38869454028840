import React from 'react'
import { localizer } from "../../../services/localizer";
import { Heading } from '../../../components/Heading';
import { MentorshipSummary } from './components/MentorshipSummary';
import styles from './styles.module.css';
import { useSearchParams } from 'react-router-dom';
import { api, getOrganizationId } from '../../../services/api';
import { Spin, message } from 'antd';
import { MentorshipDetailListSection } from './components/MentorshipDetailListSection';

export function MentorshipDetail() {
  const [mentorshipData, setMentorshipData] = React.useState(null as any);
  const [isLoading, setIsLoading] = React.useState(false);

  const [searchParams] = useSearchParams();
  const mentorshipId = searchParams.get("mentorshipId");

  React.useEffect(() => {
    loadMentorship();
  }, []);

  function loadMentorship() {
    setIsLoading(true);
    api.get(`/Partnership/${getOrganizationId()}/GetPartnershipsByOrganization/${mentorshipId}`)
      .then((response) => {
        setMentorshipData(response.data);
      }).catch((error) => {
        message.error(localizer("Internal server error"))
        console.error(error)
      }).finally(() => setIsLoading(false))
  }

  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Mentorship")} />
      {mentorshipData
        ? <>
          <MentorshipSummary mentorshipData={mentorshipData} />
          <MentorshipDetailListSection partnershipId={mentorshipId} />
        </>
        : <Spin size='large' />
      }

    </div>
  )
}
