import { Outlet } from "react-router-dom";
import './AppLayout.css';
import { Divider } from '../../components/Divider';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';

export function AppLayout() {
  return (
    <>
      <div className="app-header">
        <Header />
        <Divider />
      </div>
      <main id="main">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
