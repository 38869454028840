import { useEffect, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { localizer } from '../../../../../services/localizer';
import { api } from '../../../../../services/api';
import { useForm } from 'antd/lib/form/Form';
import { StepAttachmentForm } from '../StepAttachmentForm';
import { Attachment } from '../../../../../models/Attachment';

export function StepFormModal({ currentStep, learningWorkflowId, visibleModal, onChangeVisibleModal, onUpdateStepList }: any) {

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { TextArea } = Input;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [modalForm] = useForm();

    const [attachmentList, setAttachmentList] = useState([] as Attachment[]);

    useEffect(() => {
        setVisible(visibleModal);
    }, [visibleModal]);

    useEffect(() => {
        onChangeVisibleModal(visible)

        if(!visible || !currentStep){
            setName('')
            setDescription('')
        }

        if (visible && currentStep.id) {
            setName(currentStep.name);
            setDescription(currentStep.description);
        }
    }, [visible]);

    const handleOk = async () => {
        setConfirmLoading(true);

        if (currentStep.id) {
            await updateLearningWorkflowStep(currentStep.id);
        } else {
            await createLearningWorkflowStep();
        }

        onUpdateStepList();
    };

    async function uploadAttachmentList(learningWorkflowStepId: string) {
        for (const attachment of attachmentList) {
            var bodyFormData = new FormData();
            bodyFormData.append('supportMaterialType', attachment.supportMaterialType.toString());
            bodyFormData.append('supportMaterialLink', attachment.link);
            bodyFormData.append('supportMaterialName', attachment.name);
            bodyFormData.append('name', attachment.name);
            bodyFormData.append('file', attachment.file);

            try {
                await api.post(`/LearningWorkflow/LearningWorkflowStep/${learningWorkflowStepId}/LearningWorkflowStepSupportMaterial`, bodyFormData);
            } catch (error: any) {
                message.error({ 
                    content: error.response.data.userFriendlyMessage, 
                    duration: 6,
                    style: { 
                        fontSize: '18px', 
                        backgroundColor: 'var(--primary-color)',
                        color: 'var(--secondary-color)',
                      },              
                    });
            }
        }
    }

    async function createLearningWorkflowStep() {

        try {
            const response = await api.post(`LearningWorkflow/${learningWorkflowId}/LearningWorkflowStep`, {
                name: name,
                description: description
            })
            await uploadAttachmentList(response.data.id)
        } catch (error) {
            console.error(error);
        } finally {
            setVisible(false);
            setConfirmLoading(false);
        }
    }

    async function updateLearningWorkflowStep(learningWorkflowStepId: any) {

        try {
            await api.put(`LearningWorkflow/LearningWorkflowStep/${learningWorkflowStepId}`, {
                name: name,
                description: description
            })
            await uploadAttachmentList(learningWorkflowStepId)
        } catch (error) {
            console.error(error);
        } finally {
            setVisible(false);
            setConfirmLoading(false);
        }
    }

    const handleCancel = () => {
        setVisible(false);
        setAttachmentList([]);
    };

    const handleUpdateAttachmentList = (attachmentList: Attachment[]) => {
        setAttachmentList(attachmentList);
    }

    return (
        <>
            <Modal
                title={localizer("Add Step")}
                onOk={() => modalForm.submit()}
                onCancel={handleCancel}
                visible={visible}
                confirmLoading={confirmLoading}
                width={800}
                destroyOnClose={true}
            >
                <Form
                    form={modalForm}
                    name="basic"
                    preserve={false}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={handleOk}
                >
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: localizer('Please input the name of this step.') }]}
                        initialValue={name}
                    >
                        <Input placeholder={localizer("Name")} onChange={(e) => setName(e.target.value)} value={name}></Input>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        initialValue={description}
                    >
                        <TextArea rows={3} placeholder={localizer("Description")}
                            onChange={(e) => setDescription(e.target.value)} value={description} />
                    </Form.Item>

                    <StepAttachmentForm
                        currentAttachmentList={currentStep.learningWorkflowStepSupportMaterialList}
                        onUpdateAttachmentList={handleUpdateAttachmentList}
                        onDeleteAttachment={onUpdateStepList} />

                </Form>
            </Modal>
        </>
    )
}