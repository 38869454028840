import axios from "axios";
import { Localizer } from "./localizer";

export function setAuthToken(authToken: string, userUid: string) {
    localStorage.setItem('idToken', authToken);
    localStorage.setItem('userUid', userUid);
}

export function setOrganizationId(organizationId: number) {
    localStorage.setItem('organizationId', organizationId.toString());
}

export function setUserId(userId: number) {
    localStorage.setItem('userId', userId.toString());
}

export function getUserUid(): string | null {
    return localStorage.getItem('userUid');
}

export function getUserId(): number | null {
    return Number(localStorage.getItem('userId')) || null;
}

export function getOrganizationId() {
    return localStorage.getItem('organizationId');
}

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
    const idToken = localStorage.getItem('idToken') ?? '';
    const bearerAuthToken = `Bearer ${idToken}`;
    config!.headers!["Authorization"] = bearerAuthToken;
    config!.headers!["Accept-Language"] = Localizer.systemCulture;
    return config;
});
