import { api } from "./api";
import { addHours } from "date-fns"

export function localizer(key: string): string {
  if (!Localizer.translations) {
    return key;
  }

  const translations = JSON.parse(Localizer.translations) as any[];
  let value = translations
    .find((x: any) => x[Localizer.defaultCulture] == key);

  if (!value) {
    Localizer.sendNewTranslationToService(key)
    value = { "pt-BR": "todo", "es-ES": "todo", "en-US": key };
  }

  if (Localizer.systemCulture == Localizer.defaultCulture) {
    return key;
  }

  return value[Localizer.systemCulture];
}

export class Localizer {

  private static defaultCacheTimeout = 24;
  static supportedCultures = ['en-US', 'pt-BR', 'es-ES'];
  static defaultCulture = "en-US";
  static translations = localStorage.getItem("translations") ?? "";
  private static translationsSetDate = parseInt(localStorage.getItem("translations-set-date") ?? "0");

  static systemCulture = localStorage.getItem("systemCulture") ??
    (this.supportedCultures.includes(navigator.language)
      ? navigator.language
      : this.defaultCulture);

  static changeLanguage(language: string) {
    localStorage.setItem("systemCulture", language);
    window.location.href = this.upsertQueryStringParameter(window.location.href, "l", language);
  }

  private static upsertQueryStringParameter(uri: string, key: string, value: string) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  private static isCacheExpired(): boolean {
    if (!Localizer.translationsSetDate)
      return true;

    const dateTimeout = addHours(new Date(Localizer.translationsSetDate),
      Localizer.defaultCacheTimeout).getTime();

    return dateTimeout < new Date().getTime();
  }

  private static loadTranslations() {
    this.translations = localStorage.getItem("translations") ?? "";
    this.translationsSetDate = parseInt(localStorage.getItem("translations-set-date") ?? "0");
  }

  static async updateTranslationsOnSharedPreferences(force = false) {
    if (!force) {
      if (!this.isCacheExpired()) {
        return;
      }
    }

    var response = await api.get("/Translation");
    localStorage.setItem("translations", JSON.stringify(response.data));
    localStorage.setItem("translations-set-date", new Date().getTime().toString());
    this.loadTranslations();
  }

  public static sendNewTranslationToService(key: string): void {
    var data = { "key": key, "cultureName": this.defaultCulture };
    api.post("/Translation", data)
      .then(async () => {
        await this.updateTranslationsOnSharedPreferences(true);
      })
      .catch((error) => console.error(error));
  }
}
