import { Button, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { localizer } from "../../../services/localizer";
import styles from './styles.module.css';
import { ComplaintLevelEnum } from '../../../models/ComplaintLevelEnum';

type ComplaintDetailModalProps = {
  visible: boolean,
  complaintId: string,
  onCancel: () => void,
  onFinishModal: () => void
}
export function ComplaintDetailModal(props: ComplaintDetailModalProps) {

  const [isLoading, setIsLoading] = useState(true);
  const [complaint, setComplaint] = useState({} as any);

  useEffect(() => {
    if (props.complaintId) {
      setIsLoading(true);
      api.get(`/Complaint/${props.complaintId}`)
        .then(response => {
          setComplaint(response.data);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [props.complaintId]);

  return (
    <Modal
      title={localizer("Complaint")}
      visible={props.visible}
      width={950}
      destroyOnClose={true}
      onCancel={props.onCancel}
      footer={[
        <Button type="link" key="close" onClick={props.onCancel}>
          {localizer("Close")}
        </Button>
      ]}
    >
      {isLoading
        ? <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
        :
        <div className={styles.contentWrapper}>
          <div>
            <div>
              <span>{localizer('Reported by')}: </span>
              <span className={styles.primaryText}>
                {complaint.reportedByUserName}
              </span>
            </div>
            <div>
              <span>{localizer('Level')}: </span>
              <span className={styles.primaryText}>
                {localizer(ComplaintLevelEnum[complaint.complaintLevel])}
              </span>
            </div>
            <div>
              <span>{localizer('Date')}: </span>
              <span className={styles.primaryText}>
                {new Date(complaint.createdDate).toLocaleString()}
              </span>
            </div>
            <div>
              <strong>{localizer('Accusation')}: </strong>
              <p>{complaint.description}</p>
            </div>

          </div>
        </div>

      }
    </Modal>
  );
}
