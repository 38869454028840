import React from 'react'
import styles from './styles.module.css';
import { Button, Space, Spin, Table } from 'antd';
import { localizer } from '../../../../../services/localizer';
import { api, getOrganizationId } from '../../../../../services/api';
import { useNavigate } from 'react-router-dom';
import LockCalendar from '../../../../../assets/calendar-lock-icon.png';
import UnlockCalendar from '../../../../../assets/calendar-unlock-icon.png';
import { ComplaintSearchOrderByEnum } from '../../../../../models/ComplaintSearchOrderByEnum';
import { ComplaintLevelEnum } from '../../../../../models/ComplaintLevelEnum';
import { ComplaintDetailModal } from '../../../../Complaint/ComplaintDetailModal';

export function MentorshipDetailListSection({ partnershipId }: any) {
  const [isLoading, setIsLoading] = React.useState(false);

  const [complaintList, setComplaintList] = React.useState([]);
  const [complaintId, setComplaintId] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(1);
  const [complaintListTotal, setComplaintListTotal] = React.useState(0);

  const [learningSessionList, setLearningSessionList] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);

  const navigate = useNavigate()

  React.useEffect(() => {
    setIsLoading(true)
    Promise.all([
      loadLearningSessionList(),
      loadComplaintList()
    ]).catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  async function loadLearningSessionList() {
    const response = await api
      .get(`/LearningSession/LearningSessionPartnershipViewList/${partnershipId}`);

    setLearningSessionList(response.data);
  }

  async function loadComplaintList() {
    const params = {
      complaintSearchOrderByEnum: ComplaintSearchOrderByEnum.CreatedDateDesc,
      partnershipId: partnershipId,
      PageNumber: pageNumber,
      PageSize: 10
    };

    setIsLoading(true);
    const response = await api.get(`/Complaint/${getOrganizationId()}/GetComplaintsByOrganization`, { params });
    setComplaintList(response.data.complaintDtoList);
    setComplaintListTotal(response.data.complaintListTotal);
  }

  const learningSessionColumns = [
    {
      title: localizer('Scheduled Date'),
      dataIndex: 'scheduledDate',
      render: (date: string) => {
        const now = new Date().getTime();
        const scheduledDate = new Date(date).getTime();

        const calendarIcon = (scheduledDate > now)
          ? UnlockCalendar
          : LockCalendar;

        return (
          <Space>
            <img
              className={calendarIcon === UnlockCalendar
                ? styles.imageFilter
                : ""}
              width={30} src={calendarIcon} alt="Calendar icon"
            />
            <span>{new Date(date).toLocaleString()}</span>
          </Space>
        )
      }
    },
    {
      title: localizer('Action'),
      dataIndex: 'id',
      key: 'action',
      render: (idValue: string) =>
        <Button ghost type="default"
          onClick={() => {
            navigate(`/learning-session/detail/${idValue}?mentorshipId=${partnershipId}`)
          }}>
          {localizer("View")}
        </Button>,
    },
  ];

  const complaintColumns = [
    {
      title: localizer('Reported by'),
      dataIndex: 'reportedByUserName',
    },
    {
      title: localizer('Level'),
      dataIndex: 'complaintLevel',
      render: (level: number) => {
        return localizer(ComplaintLevelEnum[level]);
      }
    },
    {
      title: localizer('Date'),
      dataIndex: 'createdDate',
      render: (date: string) => new Date(date).toLocaleString()
    },
    {
      title: localizer('Action'),
      dataIndex: 'id',
      key: 'action',
      render: (idValue: string) =>
        <Button ghost type="default"
          onClick={() => {
            setComplaintId(idValue)
            setModalVisible(true)
          }
          }>
          {localizer("View")}
        </Button>,
    },
  ];

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.tableArea}>
        <div>
          <div className={styles.subjectArea}>
            <h3>{localizer("Learning Sessions")}</h3>
          </div>
          <div>
            <Spin spinning={isLoading} size="large">
              <Table
                pagination={{ pageSize: 3 }}
                rowKey={'id'}
                bordered
                dataSource={learningSessionList}
                columns={learningSessionColumns}
                scroll={{ x: true }}
              />
            </Spin>
          </div>
        </div>
        <div>
          <div className={styles.talentCoinArea}>
            <div>
              <h3>{localizer("Complaints")}</h3>
            </div>
          </div>
          <div>
            <Spin spinning={isLoading} size="large">
              <Table rowKey={'id'}
                bordered
                pagination={{
                  total: complaintListTotal,
                  current: pageNumber,
                  showSizeChanger: false,
                  onChange: (page) => { setPageNumber(page) }
                }}
                dataSource={complaintList}
                columns={complaintColumns}
                scroll={{ x: true }}
              />
            </Spin>
          </div>
        </div>
      </div>

      <ComplaintDetailModal
        visible={modalVisible}
        complaintId={complaintId}
        onCancel={() => setModalVisible(false)}
        onFinishModal={() => setModalVisible(false)}
      />
    </div>
  )
}
