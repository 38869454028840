import styles from './styles.module.css';
import { localizer } from '../../../../../services/localizer';
import { UserOutlined } from '@ant-design/icons';
import { PartnershipStatus } from '../../../../../models/PartnershipStatus';

export function MentorshipSummary({ mentorshipData }: any) {

  return (
    <section className={styles.profileWrapper}>
      <div className={styles.studentData}>
        <div>
          <div className={styles.profileImg}>
            {mentorshipData.mentorProfileImage
              ? <img
                src={mentorshipData.mentorProfileImage}
                alt="avatar" />
              : <UserOutlined style={{ fontSize: '3rem' }} />
            }
          </div>
          <div className={styles.name}>
            <span>{localizer('Mentor')}</span>
            <span className={styles.primaryText}>
              {mentorshipData.mentorName}
            </span>
          </div>
        </div>
        <div>
          <div className={styles.profileImg}>
            {mentorshipData.apprenticeProfileImage
              ? <img
                src={mentorshipData.apprenticeProfileImage}
                alt="avatar" />
              : <UserOutlined style={{ fontSize: '3rem' }} />
            }
          </div>
          <div className={styles.name}>
            <span>{localizer('Apprentice')}</span>
            <span className={styles.primaryText}>
              {mentorshipData.apprenticeName}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.mentorshipInfo}>
        <div className={styles.displayInfo}>
          <span>{localizer('Subject')}</span>
          <span className={styles.primaryText}>
            {mentorshipData.subjectName}
          </span>
        </div>
        <div className={styles.displayInfo}>
          <span>{localizer('Status')}</span>
          <span className={styles.primaryText}>
            {localizer(PartnershipStatus[mentorshipData.partnershipStatus])}
          </span>
        </div>
        <div className={styles.displayInfo}>
          <span>{localizer('Created at')}</span>
          <span className={styles.primaryText}>
            {new Date(mentorshipData.createdDate).toLocaleString()}
          </span>
        </div>
      </div>
    </section>
  )
}
