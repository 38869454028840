export enum PartnershipAdminSearchOrderByEnum {
    SubjectNameAsc = 1,
    SubjectNameDesc = 2,
    MentorNameAsc = 3,
    MentorNameDesc = 4,
    ApprenticeNameAsc = 5,
    ApprenticeNameDesc = 6,
    CreatedDateAsc = 7,
    CreatedDateDesc = 8,
    StatusAsc = 9,
    StatusDesc = 10,
    LearningSessionAmountAsc = 11,
    LearningSessionAmountDesc = 12,

    'subjectName' = 1,
    'mentorName' = 3,
    'apprenticeName' = 5,
    'createdDate' = 7,
    'partnershipStatus' = 9,
    'learningSessionAmount' = 11
}