import React from 'react'
import styles from './styles.module.css';
import { Spin, Table, Tabs } from 'antd';
import { localizer } from '../../../../../services/localizer';
import { api, getOrganizationId } from '../../../../../services/api';
import { PartnershipStatus } from '../../../../../models/PartnershipStatus';

export function StudenDetailMentorship({ studentId }: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apprenticeMentorshipList, setApprenticeMentorshipList] = React.useState([]);
  const [mentorMentorshipList, setMentorMentorshipList] = React.useState([]);

  const { TabPane } = Tabs;

  React.useEffect(() => {
    setIsLoading(true);

    const params = {
      partnershipSearchOrderBy: 3,
      PageNumber: 1,
      PageSize: 10,
      studentApprenticeId: studentId,
      mentorNameOrSubject: null,
      subjectId: null,
      organizationId: getOrganizationId(),
      status: null
    }

    api.get(`/Partnership/PartnershipAsApprentice`, { params }).then((response) => {
      setApprenticeMentorshipList(response.data);
    })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false))
  }, [])

  React.useEffect(() => {
    setIsLoading(true);

    const params = {
      partnershipSearchOrderBy: 3,
      PageNumber: 1,
      PageSize: 10,
      studentMentorId: studentId,
      apprenticeName: null,
      subjectId: null,
      organizationId: getOrganizationId(),
      status: null
    }

    api.get(`/Partnership/PartnershipAsMentor`, { params }).then((response) => {
      setMentorMentorshipList(response.data);
    })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false))
  }, [])

  const mentorshipColumns = [
    {
      title: localizer('You Are Teaching'),
      dataIndex: 'subjectName',
    },
    {
      title: localizer('Apprentice'),
      dataIndex: 'apprenticeName',
    },
    {
      title: localizer('Status'),
      dataIndex: 'partnershipStatus',
      render: (partnershipStatus: number) => {
        return localizer(PartnershipStatus[partnershipStatus].toString());
      }
    },
    {
      title: localizer('Created'),
      dataIndex: 'createdDate',
      render: (date: string) => new Date(date).toLocaleString()
    },
    {
      title: localizer('Learning Session'),
      dataIndex: 'learningSessions',
      render: (learningSession: any[]) => {
        return learningSession?.length;
      }
    },
  ];

  const apprenticeColumns = [
    {
      title: localizer('Mentor'),
      dataIndex: 'mentorName',
    },
    {
      title: localizer('Is Teaching You'),
      dataIndex: 'subjectName',
    },
    {
      title: localizer('Status'),
      dataIndex: 'partnershipStatus',
      render: (partnershipStatus: number) => {
        return localizer(PartnershipStatus[partnershipStatus].toString());
      }
    },
    {
      title: localizer('Created'),
      dataIndex: 'createdDate',
      render: (date: string) => new Date(date).toLocaleString()
    },
    {
      title: localizer('Learning Session'),
      dataIndex: 'learningSessions',
      render: (learningSession: any[]) => {
        return learningSession?.length;
      }
    },
  ];

  return (
    <div className={styles.contentWrapper}>
      <div>
        <div className={styles.mentorshipArea}>
          <h3>{localizer("Mentorship")}</h3>
        </div>
        <div>
          <Tabs style={{ color: '#fff' }} defaultActiveKey='1'>
            <TabPane tab={localizer("As Apprentice")} key="1">
              <Spin spinning={isLoading} size="large">
                <Table rowKey={'partnershipId'} bordered dataSource={apprenticeMentorshipList} columns={apprenticeColumns}
                  scroll={{ x: true }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={localizer("As Mentor")} key="2">
              <Spin spinning={isLoading} size="large">
                <Table rowKey={'partnershipId'} bordered dataSource={mentorMentorshipList} columns={mentorshipColumns}
                  scroll={{ x: true }}
                />
              </Spin>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
