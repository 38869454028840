import { useState } from 'react';
import { Form, Input, message, Modal, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { api, getOrganizationId } from '../../../services/api';
import { localizer } from '../../../services/localizer';
import React from 'react';

export function ClassCreateModal({ visible, organizationClassId, onCancel, onFinishModalForm }: any) {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReadingDataLoading, setIsReadingDataLoading] = useState(false);

  const [modalForm] = useForm();
  const url = "/OrganizationClass";

  React.useEffect(() => {
    if (!visible || !organizationClassId) {
      setName('');
      return;
    }

    setIsReadingDataLoading(true);
    api.get(`${url}/${organizationClassId}`).then((response) => {
      setName(response.data.name);
    }).catch((error) => console.error(error))
      .finally(() => setIsReadingDataLoading(false))
  }, [organizationClassId, visible])

  const handleOnFinishForm = async () => {
    setIsLoading(true);

    const payload = {
      name,
      isActive: true,
      organizationId: getOrganizationId(),
    };

    try {
      if (organizationClassId) {
        await api.put(`${url}/${organizationClassId}`, payload);
      } else {
        await api.post(url, payload);
      }
    } catch (error) {
      console.error(error)
      message.error({
        content: localizer("Internal server error"),
        duration: 6, 
        style: { 
          fontSize: '18px', 
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
        },
      });
    } finally {
      setIsLoading(false);
    }

    onFinishModalForm();
  }

  return (
    <Modal
      title={organizationClassId ? localizer("Edit class") : localizer("Add class")}
      onOk={() => modalForm.submit()}
      visible={visible}
      confirmLoading={isLoading}
      width={600}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <Form
        form={modalForm}
        name="createClassModal"
        preserve={false}
        autoComplete="off"
        onFinish={handleOnFinishForm}
      >
        {
          isReadingDataLoading
            ? <Spin />
            : (
              <Form.Item
                name="name"
                initialValue={name}
                rules={[{ required: true, message: localizer('Please input the name.') }]}
              >
                <Input placeholder={localizer("Name")}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
            )
        }
      </Form>
    </Modal>
  )
}