export enum RecommendationSearchOrderByEnum {
    SubjectNameAsc = 1,
    SubjectNameDesc = 2,
    MentorNameAsc = 3,
    MentorNameDesc = 4,
    ApprenticeNameAsc = 5,
    ApprenticeNameDesc = 6,
    CreatedByNameAsc = 7,
    CreatedByNameDesc = 8,
    CreatedDateAsc = 9,
    CreatedDateDesc = 10,
    StatusAsc = 11,
    StatusDesc = 12,

    'subjectName' = 1,
    'mentorName' = 3,
    'apprenticeName' = 5,
    'creatorUserName' = 7,
    'createdDate' = 9,
    'partnershipRecommendationStatus' = 11
}