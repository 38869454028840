import { Avatar, Button, Image, Modal, Progress, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { localizer } from '../../../../../services/localizer';
import styles from './styles.module.css';
import { DownloadOutlined, LinkOutlined, DeleteFilled, EditFilled, FileOutlined } from '@ant-design/icons';
import { SupportMaterialType } from '../../../../../models/SupportMaterialType';
import { api } from '../../../../../services/api';

export function Step({ step, onEditClick, onDeleteStep, readonly }: any) {

  const [isOpen, setIsOpen] = useState(false);
  const { confirm } = Modal;

  const toggle = () => setIsOpen(!isOpen);

  const handleEditClick = () => {
    onEditClick(step.id);
  }

  function handleDeleteClick() {
    confirm({
      content: localizer("Are you sure you want to permanently remove this step?"),
      okText: localizer("Confirm"),
      onOk: async () => {
        try {
          await api.delete(`/LearningWorkflow/LearningWorkflowStep/${step.id}`)
          onDeleteStep()
        } catch (error) {
          console.error(error)
        }
      }
    });
  }

  const getAttachmentIconBySupportMaterialType = (supportMaterialType: SupportMaterialType) => {
    switch (supportMaterialType) {
      case SupportMaterialType.Link:
        return <LinkOutlined />;
      case SupportMaterialType.File:
        return <FileOutlined />;
      default:
        return <DownloadOutlined />;
    };
  }

  return (
    <>
      <div className={styles.heading} onClick={toggle}>

        {isOpen
          ? (<FaMinus size="20" />)
          : (<FaPlus size="20" />)}
        <div>{step.name}</div>
      </div>

      <div className={`${styles.stepDetailWrapper} ${isOpen ? styles.openedStep : styles.closedStep}`}>
        {!readonly &&
          <div>
            <Space>
              <Tooltip title={localizer("Edit")}>
                <Button onClick={handleEditClick} type="primary" shape="circle" icon={<EditFilled />} />
              </Tooltip>
              <Tooltip title={localizer("Delete")}>
                <Button onClick={handleDeleteClick} type="primary" shape="circle" icon={<DeleteFilled />} />
              </Tooltip>
            </Space>
          </div>
        }
        <div className={styles.stepDetail}>
          <div>
            <h4>{step.name}</h4>
            {readonly &&
              <>
                <div><strong>{localizer("Mentor used")}: </strong>Yes</div>
                <div>
                  <strong>{localizer("Apprentice understanding level")}:</strong>
                  <div style={{ width: "80%" }}>
                    <Progress percent={step.comprehensionLevel * 10} strokeColor="#ff7300" showInfo={false} />
                  </div>
                </div>
                <div><strong>{localizer("Description")}:</strong></div>
              </>
            }
            <p>{step.description}</p>
          </div>
          <div>
            <h4>{localizer("Attachments")}</h4>
            <div>
              <ul>
                { 
                  step 
                  && step.learningWorkflowStepSupportMaterialList 
                  && step.learningWorkflowStepSupportMaterialList.length > 0 ?
                      step.learningWorkflowStepSupportMaterialList.map( (supportMaterial: any, index: any) => (
                        <li key={index}>
                          <>
                            {
                              supportMaterial.supportMaterialType === SupportMaterialType.Image
                                ?
                                <Avatar shape='square' size={35}
                                  src={<Image src={supportMaterial.supportMaterialLink} style={{ width: 35, objectFit: 'cover' }} />} />
                                :
                                <div style={{ width: 35, display: 'inline-block' }}></div>
                            }
                            <a href={supportMaterial.supportMaterialLink} target="_blank">
                              <Button type='link'
                                icon={getAttachmentIconBySupportMaterialType(supportMaterial.supportMaterialType)}>
                                {supportMaterial.name}
                              </Button>
                            </a>
                          </>
                        </li>
                      ))
                  : <></>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );




}