import styles from './styles.module.css';
import { localizer } from '../../../../services/localizer';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom'

export function PreviewAreaSubjectCard({ subject, index }: any) {
  let navigate = useNavigate();

  async function handleSeeMore() {
    navigate(`/subject-detail/${subject.id}`);
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.subjectData}>
        <div>
          <div className={styles.rankNumber}>
            {index}°
          </div>
          <div>
            <div className={styles.subjectName}>{subject.name.toUpperCase()}</div>
            <div className={styles.subjectCode}>{subject.code.toUpperCase()}</div>
          </div>
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <Button onClick={handleSeeMore} ghost type="default">{localizer("View")}</Button>
      </div>
    </div>
  );
}