import { initializeApp } from "firebase/app";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import { setAuthToken } from "../api";
import { useLocation, useNavigate } from "react-router-dom";

export function useFirebaseInitialize() {

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID
    };

    // Initialize Firebase
    initializeApp(firebaseConfig);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const auth = getAuth();

    onIdTokenChanged(auth, async (user) => {
        if (user) {
            const idToken = await user.getIdToken();
            setAuthToken(idToken, user.uid);
            if (pathname.startsWith("/login"))
                navigate('/dashboard');
        }
        else {
            setAuthToken('', '');
            if (!pathname.startsWith('/login'))
                navigate('/login');
        }
    });
}
