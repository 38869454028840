import React from 'react'
import { localizer } from "../../../services/localizer";
import { Heading } from '../../../components/Heading';
import styles from './styles.module.css';
import { useParams, useSearchParams } from 'react-router-dom';
import { Spin, message, Rate, Radio, Button } from 'antd';
import { api, getOrganizationId } from '../../../services/api';
import { LearningSessionLearningWorkflowModal } from '../LearningSessionLearningWorkflowModal';

export function LearningSessionDetail() {
  const [learningSession, setLearningSession] = React.useState(null as any);
  const [mentorshipData, setMentorshipData] = React.useState(null as any);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [learningSessionEnded, setlearningSessionEnded] = React.useState(false);

  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const mentorshipId = searchParams.get("mentorshipId");

  React.useEffect(() => {
    loadStudentData();
    loadMentorship();
  }, [])

  function loadStudentData() {
    setIsLoading(true);
    api.get(`/LearningSession/GetLearningSessionById/${id}`).then(async (response) => {
      const { data: learningSession } = response;
      const scheduledDate = new Date(learningSession.scheduledDate).getTime();
      const now = new Date().getTime();

      if (scheduledDate < now) {
        setlearningSessionEnded(true);
        const { data: endedLearningSession } = await api
          .get(`/LearningSession/GetLearningSessionEndedById/${id}`);

        setLearningSession(endedLearningSession);
      } else {
        setLearningSession(learningSession);
      }
    }).catch((error) => {
      message.error({
      content: localizer("Internal server error"),
      duration: 6, 
      style: { 
        fontSize: '18px', 
        backgroundColor: 'var(--primary-color)',
        color: 'var(--secondary-color)',
      },
    });
      console.error(error)
    }).finally(() => setIsLoading(false))
  }

  function loadMentorship() {
    setIsLoading(true);
    api.get(`/Partnership/${getOrganizationId()}/GetPartnershipsByOrganization/${mentorshipId}`)
      .then((response) => {
        setMentorshipData(response.data);
      }).catch((error) => {
        message.error({
          content: localizer("Internal server error"),
          duration: 6, 
          style: { 
            fontSize: '18px', 
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)'
          },
        })
        console.error(error)
      }).finally(() => setIsLoading(false))
  }

  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Learning Session")} />
      {
        (!learningSession || !mentorshipData)
          ? <Spin size='large' />
          : <div className={styles.box}>
            <h2>{localizer("Finished Learning Session")}</h2>
            <div>
              <span>{localizer("Subject")}: {learningSession.subjectName}</span>
            </div>
            <div>
              <span>{localizer("Scheduled date")}: {new Date(learningSession.scheduledDate).toLocaleString()}</span>
            </div>
            {
              learningSessionEnded && (
                <section className={styles.reviewSection}>
                  <div>
                    <div>
                      {localizer("Apprentice review")} - {mentorshipData.apprenticeName}
                    </div>
                    <div>
                      {learningSession.feedbackApprenticeToMentor}
                    </div>
                    <div>
                      <span>
                        <Rate value={learningSession.ratingReviewApprenticeToMentor} disabled />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      {localizer("Mentor review")} - {mentorshipData.mentorName}
                    </div>
                    <div>
                      {learningSession.feedbackMentorToApprentice}
                    </div>
                    <div>
                      <span>
                        <Rate value={learningSession.ratingReviewMentorToApprentice} disabled />
                      </span>
                    </div>
                  </div>
                </section>
              )
            }
            <section className={styles.goalsSection}>
              <h2>{localizer("Goals")}</h2>
              <div className={styles.goalsGroup}>
                <div className={styles.sessionGroup}>
                  {learningSessionEnded && (
                    <div>
                      <h3>{localizer("For next session")}</h3>
                      <div>
                        {learningSession.apprenticeGoalNextSession}
                      </div>
                    </div>
                  )}
                  <div>
                    <h3>{localizer("Previous session")}</h3>
                    <div>
                      {learningSession.apprenticeGoalPreviousSession}
                    </div>
                  </div>
                </div>
                <div>
                  <h4>{localizer("Apprentice fulfilled previous session goal?")}</h4>
                  <Radio.Group
                    value={learningSession.apprenticeFulfilledGoalPreviousSession ? 1 : 2}
                    disabled
                  >
                    <Radio value={1}><span className={styles.text}>{localizer("Yes")}</span></Radio>
                    <Radio value={2}><span className={styles.text}>{localizer("No")}</span></Radio>
                  </Radio.Group>
                </div>
              </div>
            </section>
            <div>
              <h3>{localizer("Location/Meeting link")}</h3>
              {learningSession.locationMeetingLink}
            </div>
            <div className={styles.openModalButton}>
              <Button
                onClick={() => setModalVisible(true)}
              >
                {localizer("See learning workflow")}
              </Button>
            </div>
            <LearningSessionLearningWorkflowModal
              visible={modalVisible}
              learningSessionLearningWorkflowId={learningSession.learningSessionLearningWorkflowId}
              mentorName={mentorshipData.mentorName}
              apprenticeName={mentorshipData.apprenticeName}
              subjectName={mentorshipData.subjectName}
              onCancel={() => setModalVisible(false)}
              onFinishModalForm={() => {
                setModalVisible(false);
              }} />
          </div>
      }
    </div>
  )
}
