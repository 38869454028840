import styles from './styles.module.css';
import { PreviewAreaCardGroup } from '../PreviewAreaCardGroup';
import { PreviewAreaStudentCard } from '../PreviewAreaStudentCard';
import { PreviewAreaSubjectCard } from '../PreviewAreaSubjectCard';
import { localizer } from '../../../../services/localizer';

export function PreviewArea({ adminHomeData, recommendButtonClick }: any) {

  function getMapToStudentDto(student: any) {
    const nameArray = (student.name as string).split(" ");

    return {
      id: student.studentId as number,
      user: {
        firstName: nameArray.shift() as string,
        lastName: nameArray.length > 1
          ? nameArray.join(" ") as string
          : "",
        email: student.email as string,
        profileImageBase64: student.profileImage as string,
        talentCoins: student.talentCoins as number
      },
      classes: student.classes
    }
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.halfBackgroud}>
      </div>
      <div className={styles.cardGroupWrapper}>
        <PreviewAreaCardGroup
          withButton={true}
          title={localizer("Top Mentors by Teaching")}
          seeAllRoute="/student/list">
          {(adminHomeData.topMentorsByTeaching as [])
            .map((x: any, index) =>
              <PreviewAreaStudentCard key={x.student.studentId} recommendButtonClick={recommendButtonClick} student={getMapToStudentDto(x.student)} index={index + 1} />
            )}
        </PreviewAreaCardGroup>
        <PreviewAreaCardGroup
          withButton={true}
          title={localizer("Top Apprentices by Learning")}
          seeAllRoute="/student/list">
          {(adminHomeData.topApprenticesByLearning as [])
            .map((x: any, index) =>
              <PreviewAreaStudentCard  key={x.student.studentId} recommendButtonClick={recommendButtonClick} student={getMapToStudentDto(x.student)} index={index + 1} />
            )}
        </PreviewAreaCardGroup>
        <PreviewAreaCardGroup
          withButton={true}
          title={localizer("Top Students by Talent Coins")}
          seeAllRoute="/student/list">
          {(adminHomeData.topStudentsByTalentCoins as [])
            .map((x: any, index) =>
              <PreviewAreaStudentCard key={x.student.studentId} recommendButtonClick={recommendButtonClick} student={getMapToStudentDto(x.student)} index={index + 1} />
            )}
        </PreviewAreaCardGroup>
        <PreviewAreaCardGroup
          title={localizer("Top Mentoring Subjects")}
          seeAllRoute="/subject/list">
          {(adminHomeData.topMentoringSubjects as [])
            .map((x: any, index) =>
              <PreviewAreaSubjectCard key={x.subject.id} subject={x.subject} index={index + 1} />
            )}
        </PreviewAreaCardGroup>
      </div>
    </div >
  );
}