import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { FaPlus } from "react-icons/fa";

import { Step } from '../Step';
import { localizer } from '../../../../../services/localizer';
import { api } from '../../../../../services/api';
import { StepFormModal } from '../StepFormModal';
import { Spin } from 'antd';

export function StepList({ learningWorkflowId }: any) {

  const [stepList, setStepList] = useState([] as any[]);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState({} as any);

  useEffect(() => {
    handleUpdateStepList();
  }, [learningWorkflowId]);

  const showModal = () => {
    setVisible(true);
  };

  const handleAddStepClick = () => {
    setCurrentStep({});
    showModal()
  }

  useEffect(() => {
    if (currentStep) {
      setCurrentStepById(currentStep.id);
    }
  }, [stepList]);

  function setCurrentStepById(stepId: number) {
    const step = stepList.find(x => x.id === stepId);
    if (step) {
      setCurrentStep(step);
    }
  }

  const handleChangeVisibleModal = (visibleModal: boolean) => {
    setVisible(visibleModal);
  }

  const handleUpdateStepList = () => {
    if (learningWorkflowId) {
      setIsLoading(true);
      api.get(`/LearningWorkflow/${learningWorkflowId}/GetLearningWorkflow`).then(x => {
        setStepList(x.data.learningWorkflowStepList);
      })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }

  const handleEditClick = (stepId: any) => {
    setCurrentStepById(stepId);
    showModal();
  }

  return (
    <>
      {learningWorkflowId &&
        <div>
          <div className={styles.stepListBackground}>
          </div>

          <div className={styles.stepList}>
            <div className={styles.heading}>
              <h4>{localizer("Learning workflow")}</h4>
              <button onClick={handleAddStepClick}>
                <FaPlus size="14" />
                <span>{localizer("Add Step")}</span>
              </button>
            </div>
            <Spin spinning={isLoading}>
              {
                stepList.map((step) =>
                  <Step key={step.id} step={step} onEditClick={handleEditClick} onDeleteStep={handleUpdateStepList} />
                )
              }
            </Spin>
          </div>
        </div>
      }

      <StepFormModal
        visibleModal={visible}
        onChangeVisibleModal={handleChangeVisibleModal}
        learningWorkflowId={learningWorkflowId}
        onUpdateStepList={handleUpdateStepList}
        currentStep={currentStep}
      ></StepFormModal>
    </>

  );
}