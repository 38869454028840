import React from "react";
import { Heading } from "../../components/Heading";
import { localizer } from "../../services/localizer";
import { PreviewArea } from "./components/PreviewArea";
import ChartStats from "./components/ChartStats";
import styles from "./styles.module.css";
import { api, getUserUid, setOrganizationId, setUserId } from "../../services/api";
import { Spin } from "antd";
import { RecommendationCreateModal } from "../Recommendation/RecommendationCreateModal";
import { signOut } from "@firebase/auth";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export function Dashboard() {
  const [adminHomeData, setAdminHomeData] = React.useState(null as any)
  const [isLoading, setIsLoading] = React.useState(false)
  const [recommendationModalVisible, setRecommendationModalVisible] = React.useState(false);
  const [dates, setDates] = React.useState([null, null]);
  const [topAmount, setTopAmount] = React.useState(10);
  const navigate = useNavigate();

  const userUid = getUserUid();

  React.useEffect(() => {
    setIsLoading(true);

    api.get(`/User/${userUid}/GetUserByUID`).then(async (userResponse) => {
      const organizationId = userResponse.data.organizationId;
      setOrganizationId(organizationId);
      setUserId(userResponse.data.id);
      const [startDate, endDate] = dates;

      const params = {
        userId: userResponse.data.id,
        userType: userResponse.data.userType,
        from: startDate,
        to: endDate,
        top: topAmount
      };

      api.get(`/Administrator/${organizationId}/AdminHomeData`, { params })
      .then(res => {
        const adminHomeResponse = res;
        setAdminHomeData(adminHomeResponse.data);
      })
      .catch(error => {
        if ( error.response.status === 401 ) {
            signOut(getAuth()).then(() => {
              navigate('/login');
            });
        }
      })

    })
      .catch(error => console.error(error))
      .finally(() => setIsLoading(false))
  }, [dates, topAmount]);

  return (
    <div className={styles.contentWrapper}>
      <Spin spinning={isLoading} className={styles.spin}>
        {adminHomeData &&
          (
            <>
              <Heading title={`${localizer("Hi")}, ${(adminHomeData.administratorName as string).split(" ")[0]}`}>
                <div className={styles.headingChildren}>
                  <div>
                    <h2>{localizer("Organization/School")}</h2>
                    <h3>{adminHomeData.organizationName}</h3>
                  </div>
                </div>
              </Heading>
              <ChartStats adminHomeData={adminHomeData}
                onSetDates={(dates: any[]) => { setDates(dates) }}
                onSetTopAmount={(value: number) => setTopAmount(value)}
              />
              <PreviewArea adminHomeData={adminHomeData} recommendButtonClick={() => setRecommendationModalVisible(true)} />
            </>
          )}
      </Spin>
      <RecommendationCreateModal
        visible={recommendationModalVisible}
        onCancel={() => setRecommendationModalVisible(false)}
        onFinishModalForm={() => {
          setRecommendationModalVisible(false);
        }}
      />
    </div>
  );
}