import { ReactNode } from "react";
import styles from './styles.module.css'

type HeadingData = {
  children?: ReactNode,
  title: string,
  descriptionTitle?: string,
  descriptionText?: string
}

export function Heading({ children, title, descriptionTitle, descriptionText }: HeadingData) {
  return (
    <div className={styles.heading}>
      <div className={styles.title}>
        <h1>{title}</h1>
        {children}
      </div>
      {
        (descriptionTitle || descriptionText) &&
        (
          <div className={styles.description}>
            <h5>{descriptionTitle}</h5>
            <p>{descriptionText}</p>
          </div>
        )}
    </div>
  )
}