import React from 'react'
import { localizer } from "../../../services/localizer";
import { Heading } from '../../../components/Heading';
import { Profile } from './components/Profile';
import styles from './styles.module.css';
import { StudenSubjectData } from './components/StudentSubjectData';
import { StudenDetailMentorship } from './components/StudentDetailMentorship';
import { useParams } from 'react-router-dom';
import { api } from '../../../services/api';
import { Spin, message } from 'antd';
import { StudentCreateModal } from '../StudentCreateModal';

export function StudentDetail() {
  const [studentData, setStudentData] = React.useState(null as any);
  const [isLoading, setIsLoading] = React.useState(false);
  const [visibleCreateStudentModal, setVisibleCreateStudentModal] = React.useState(false);

  const { id } = useParams();

  React.useEffect(() => {
    loadStudentData();
  }, [])

  function loadStudentData() {
    setIsLoading(true);
    api.get(`/Student/${id}`).then((response) => {
      setStudentData(response.data);
    }).catch((error) => {
      message.error({
        content: localizer("Internal server error"),
        duration: 6, 
        style: { 
          fontSize: '18px', 
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
        },
      })
      console.error(error)
    }).finally(() => setIsLoading(false))
  }

  function updateStudentClass(orgClass: any) {
    if (studentData == null) {
      message.error({
        content: localizer("Internal server error"),
        duration: 6, 
        style: { 
          fontSize: '18px', 
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
        },
      })
      loadStudentData()
      return
    }
    let currentClassId = studentData.classes?.[0]?.id;
    api.post(`/Student/${studentData.id}/AddStudentClass?classId=${orgClass.value}`).then(() => {
      if (currentClassId !== undefined) {
        api.delete(`/Student/${currentClassId}/RemoveStudentClass`).then(loadStudentData)
      }
    }).catch((error) => {
      message.error({
        content: localizer("Internal server error"),
        duration: 6, 
        style: { 
          fontSize: '18px', 
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
        },
      })
      console.error(error)
      loadStudentData()
    })
    const updatedStudentData = JSON.parse(JSON.stringify(studentData))
    updatedStudentData.classes[0] = { organizationClass: { name: orgClass.label, id: orgClass.value } }
    setStudentData(updatedStudentData)
  }

  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Student")} />
      {studentData
        ? (<>
          <Profile studentData={studentData}
            onClickEdit={() => {
              setVisibleCreateStudentModal(true);
            }}
            onClassUpdate={updateStudentClass}
          />
          <StudenSubjectData subjectList={studentData.subjects}
            userId={studentData.user?.id} />
          <StudenDetailMentorship studentId={studentData.id} />
          <StudentCreateModal
            visible={visibleCreateStudentModal}
            studentId={studentData.id}
            onCancel={() => setVisibleCreateStudentModal(false)}
            onFinishModalForm={() => {
              setVisibleCreateStudentModal(false)
              loadStudentData();
            }} />
        </>)
        : <Spin size='large' />
      }

    </div>
  )
}
