import logo from '../../../../assets/logo.svg';
import { FaBars, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import styles from './styles.module.css'
import { Localizer, localizer } from "../../../../services/localizer";
import Flags from 'country-flag-icons/react/3x2';
import { Button, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';



function LoginHeader() {

  const navItemList = [
    { name: "Home", route: "/" },
    // { name: "Pilot Project", route: "#" },
    { name: "Contact", route: "mailto:contact@mentorius.app" },
    // { name: "FAQ", route: "#" }
  ];

  const mobileMenu = (
    <Menu
      theme="dark"
      mode="vertical"
      className={styles.mobileNavBar}
      disabledOverflow={true}
    >
      {navItemList.map((item, index) => {
        const key = index + 1;
        return <Menu.Item key={key} className={styles.menuItemMobile}>
          <NavLink to={item.route}>
            {item.name === "Home" ? item.name.toUpperCase() : localizer(item.name).toUpperCase()}
          </NavLink>
        </Menu.Item>
      })}
    </Menu>
  )

  return (
    <header className={styles.appHeader}>
      <div>
        <img src={logo} className={styles.appLogo} alt="logo" />
      </div>
      <div className={styles.navGroup}>
        <Dropdown className={styles.mobileNavBarButton} overlay={mobileMenu}
          trigger={['click']} placement="bottomLeft">
          <Button onClick={(e) => e.preventDefault()}><FaBars /></Button>
        </Dropdown>

        <nav className={styles.navBar}>
          {
            navItemList.map((item, index) => {
              const key = index + 1;
              return <a className={index == 0 ? styles.current : ""} href={item.route}>
                {item.name === "Home" ? item.name.toUpperCase() : localizer(item.name).toUpperCase()}
              </a>
            })
          }
        </nav>
        <div className={styles.languageSocialMediaArea}>
          <div className={styles.languageList}>
            <Flags.US onClick={() => Localizer.changeLanguage('en-US')} title='United States' />
            <Flags.BR onClick={() => Localizer.changeLanguage("pt-BR")} title='Brasil' />
            <Flags.ES onClick={() => Localizer.changeLanguage("es-ES")} title='España' />
          </div>
          <div className={styles.socialMedia}>
            <a href="https://www.instagram.com/mentoriusapp/" target="_blank"><FaInstagram size="15" color="#FFFFFF" /></a>
            <a href="https://www.facebook.com/mentoriusapp" target="_blank"><FaFacebookF size="13" color="#FFFFFF" /></a>
            <a href="https://www.youtube.com/@MentoriusApp" target="_blank"><FaYoutube size="15" color="#FFFFFF" /></a>
          </div>
        </div>
      </div>

    </header>
  )
}

export default LoginHeader;