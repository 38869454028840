import React from 'react'
import styles from './styles.module.css';
import { DatePicker, Spin, Table } from 'antd';
import { localizer } from '../../../../../services/localizer';
import { api } from '../../../../../services/api';
import { StudentType } from '../../../../../models/StudentType';

export function StudenSubjectData({ subjectList, userId }: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [talentCoinList, setTalentCoinList] = React.useState([]);
  const [dates, setDates] = React.useState([null, null] as any[]);
  const [talentCoinListTotal, setTalentCoinListTotal] = React.useState(0);

  const pageSize = 5;

  React.useEffect(() => {
    const [startDate, endDate] = dates;

    const params = {
      talentCoinReceivedFromType: 0,
      from: startDate,
      to: endDate,
      PageNumber: pageNumber,
      PageSize: pageSize,
    };

    setIsLoading(true)
    api.get(`/TalentCoinHistory/${userId}/GetTalentCoinHistoryByUserId`, { params })
      .then((response) => {
        setTalentCoinListTotal(response.data.talentCoinHistoryItemsTotal)
        setTalentCoinList(response.data.talentCoinHistoryItems);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false))
  }, [dates, pageNumber, talentCoinListTotal])

  const { RangePicker } = DatePicker;

  const subjectColumns = [
    {
      title: localizer('Name'),
      dataIndex: 'subjectName',
    },
    {
      title: localizer('Type'),
      dataIndex: 'studentSubjectType',
      render: (studentSubjectType: number) => {
        return StudentType[studentSubjectType].toString();
      }
    },
  ];

  const talentCoinColumns = [
    {
      title: localizer('Date'),
      dataIndex: 'dateReceived',
      render: (date: string) => new Date(date).toLocaleString()
    },
    {
      title: localizer('Description'),
      dataIndex: 'talentCoinReceivedReasonDescription',
    },
    {
      title: localizer('Amount'),
      dataIndex: 'talentCoinAmount',
    },
  ];

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.tableArea}>
        <div>
          <div className={styles.subjectArea}>
            <h3>{localizer("Subject")}</h3>
          </div>
          <div>
            <Spin spinning={isLoading} size="large">
              <Table
                pagination={{ pageSize: 3 }}
                rowKey={'id'}
                bordered
                dataSource={subjectList}
                columns={subjectColumns}
                scroll={{ x: true }}
              />
            </Spin>
          </div>
        </div>
        <div>
          <div className={styles.talentCoinArea}>
            <div>
              <h3>{localizer("Talent Coins")}</h3>
            </div>
            <div>
              <RangePicker
                size="large"
                onChange={(values) => {
                  const startDate = values?.[0]?.toISOString();
                  const endDate = values?.[1]?.toISOString();
                  setDates([startDate, endDate]);
                }} />
            </div>
          </div>
          <div>
            <Spin spinning={isLoading} size="large">
              <Table
                rowKey={'id'}
                bordered
                dataSource={talentCoinList}
                columns={talentCoinColumns}
                pagination={{
                  showSizeChanger: false,
                  total: talentCoinListTotal,
                  current: pageNumber,
                  pageSize: pageSize,
                  onChange: (page) => { setPageNumber(page) }
                }}
                scroll={{ x: true }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  )
}
