import { Button, Spin, Table, DatePicker } from "antd";
import React from "react";
import { Heading } from "../../../components/Heading";
import { localizer } from "../../../services/localizer";
import styles from "./styles.module.css";
import { api, getOrganizationId } from "../../../services/api";
import { PartnershipStatus } from "../../../models/PartnershipStatus";
import { MentorshipCreateModal } from "../MentorshipCreationModal";
import { useNavigateSearch } from "../../../hooks/userNavigateSearch";
import { PartnershipAdminSearchOrderByEnum } from "../../../models/PartnershipAdminSearchOrderByEnum";
import { getQueryOrderingNumber } from "../../../services/tableHelper";

export function MentorshipList() {
  const navigateSearch = useNavigateSearch();
  const { RangePicker } = DatePicker;

  const [pageNumber, setPageNumber] = React.useState(1);
  const [mentorshipListTotal, setMentorshipListTotal] = React.useState(0);
  const [mentorshipList, setMentorshipList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableSorter, setTableSorter] = React.useState({} as any);
  const [dates, setDates] = React.useState([null, null]);

  const [createModalVisible, setCreateModalVisible] = React.useState(false)

  const columns = [
    {
      title: localizer('Mentor'),
      dataIndex: 'mentorName',
      sorter: true
    },
    {
      title: localizer('Is Teaching'),
      dataIndex: 'subjectName',
      sorter: true
    },
    {
      title: localizer('Apprentice'),
      dataIndex: 'apprenticeName',
      sorter: true
    },
    {
      title: localizer('Status'),
      dataIndex: 'partnershipStatus',
      sorter: true,
      render: (partnershipStatus: number) => {
        return localizer(PartnershipStatus[partnershipStatus].toString());
      }
    },
    {
      title: localizer('Learning sessions'),
      dataIndex: 'learningSessionAmount',
      sorter: true
    },
    {
      title: localizer('Created at'),
      dataIndex: 'createdDate',
      sorter: true,
      render: (date: string) => new Date(date).toLocaleString()
    },
    {
      title: localizer('Action'),
      dataIndex: 'id',
      key: 'action',
      render: (idValue: string) =>
        <Button ghost type="default"
          onClick={() => {
            navigateSearch('/mentorship/detail', { mentorshipId: idValue })
          }}>
          {localizer("View")}
        </Button>,
    },
  ];

  React.useEffect(() => {
    loadMentorships();
  }, [pageNumber, tableSorter, dates]);

  function loadMentorships() {

    const orderingNumber = getQueryOrderingNumber(
      PartnershipAdminSearchOrderByEnum,
      PartnershipAdminSearchOrderByEnum.MentorNameAsc,
      tableSorter
    );

    const [startDate, endDate] = dates;
    const params = {
      partnershipAdminSearchOrderBy: orderingNumber,
      PageNumber: pageNumber,
      PageSize: 10,
      from: startDate,
      to: endDate
    };

    setIsLoading(true);
    api.get(`/Partnership/${getOrganizationId()}/GetPartnershipsByOrganization`, { params }).then((response) => {
      setMentorshipList(response.data.partnershipListDto);
      setMentorshipListTotal(response.data.partnershipListTotal);
    })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }

  return (
    <div className={styles.contentWrapper}>
      <Heading title={localizer("Mentorships")}>
        <div className={styles.headingChildren}>
          <div>
            <Button type="link" size="large"
              onClick={() => setCreateModalVisible(true)}>
              + {localizer("Add mentorship").toUpperCase()}
            </Button>
            <RangePicker onChange={(values) => {
              const startDate = values?.[0]?.toISOString();
              const endDate = values?.[1]?.toISOString();
              setPageNumber(1);
              setDates([startDate, endDate] as any[]);
            }}
              size="large" />
          </div>
        </div>
      </Heading >
      <div className={styles.halfBackground}></div>
      <div className={styles.mainContent}>
        <Spin spinning={isLoading} size="large">
          <Table
            dataSource={mentorshipList}
            rowKey={'id'}
            bordered
            columns={columns}
            pagination={{
              total: mentorshipListTotal,
              current: pageNumber,
              showSizeChanger: false,
              onChange: (page) => { setPageNumber(page) }
            }}
            onChange={(_, __, sorter) => {
              setTableSorter(sorter);
            }}
            scroll={{ x: true }}
          />
        </Spin>
      </div>
      <MentorshipCreateModal
        visible={createModalVisible}
        onCancel={() => setCreateModalVisible(false)}
        onFinishModalForm={() => {
          setCreateModalVisible(false);
          loadMentorships();
        }} />
    </div >
  );
}