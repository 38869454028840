import React from 'react'
import styles from './styles.module.css';
import { Button, Select } from 'antd';
import { localizer } from '../../../../../services/localizer';
import { StarFilled, DownOutlined, UserOutlined } from '@ant-design/icons';
import { RecommendationCreateModal } from '../../../../Recommendation/RecommendationCreateModal';
import {api, getOrganizationId} from "../../../../../services/api";

export function Profile({ isLoading, studentData, onClickEdit, onClassUpdate }: any) {
  const [modalVisible, setModalVisible] = React.useState(false);

  const organizationClass = studentData?.classes?.[0]?.organizationClass;

  const { Option } = Select;

  const [isClassListLoading, setIsClassListLoading] = React.useState(false);
  const [classList, setClassList] = React.useState(
      organizationClass ? [{ label: organizationClass?.name, value: organizationClass?.id }] : []
  );

  React.useEffect(() => {
    loadClassList()
  }, [])

  async function loadClassList() {
    const params = {
      classSearchOrderByEnum: 3,
      pageNumber: 1,
      pageSize: 100
    };

    setIsClassListLoading(true);
    try {
      const response = await api
          .get(`/OrganizationClass/${getOrganizationId()}/GetOrganizationClassesByOrganization`, { params });

      const selectList = response.data.organizationClassListDto
          .map((x: any) => ({ label: x.name, value: x.id }));

      setClassList(selectList);
    } catch (error) {
      console.error(error);
    } finally {
      setIsClassListLoading(false);
    }
  }

  function onClassSelected(classId: number) {
    let orgClass = classList.find((orgClass) => orgClass.value === classId)
    onClassUpdate(orgClass)
  }

  return (
    <>
      <section className={styles.profileWrapper}>
        <div className={styles.profileImg}>
          {studentData.user?.profileImageBase64
            ? <img
              src={studentData.user?.profileImageBase64}
              alt="avatar" />
            : <UserOutlined style={{ fontSize: '5rem' }} />
          }
        </div>
        <div className={styles.profilePersonalInfo}>
          <div className={styles.nameWrapper}>
            <div className={styles.name}>
              <span>{localizer('First name')}:</span>
              <span className={styles.primaryText}>
                {studentData.user?.firstName}
              </span>
            </div>
            <div className={styles.name}>
              <span>{localizer('Last name')}:</span>
              <span className={styles.primaryText}>
                {studentData.user?.lastName}
              </span>
            </div>
          </div>
          <div className={styles.email}>
            <span>{localizer('Email')}:</span>
            <span className={styles.primaryText}>{studentData.user?.email}</span>
          </div>
        </div>
        <div className={styles.class}>
          <div>
            <span className={styles.classTitle}>{localizer('Class')}:</span>
            <Select value={organizationClass?.id} className={styles.classSelect}
              loading={isClassListLoading}
              suffixIcon={<DownOutlined className={styles.selectArrowIcon} />}
              options={classList}
              onChange={onClassSelected}
            />
          </div>
          <div>
            <span className={styles.primaryText}>
              <StarFilled /> {studentData?.user?.talentCoins ?? 0}
            </span>
          </div>
        </div>
        <div className={styles.profileBtns}>
          <Button onClick={() => onClickEdit()} type='default'>{localizer('Edit').toUpperCase()}</Button>
          <Button onClick={() => setModalVisible(true)} type='primary'>{localizer('Recommend').toUpperCase()}</Button>
        </div>
      </section>
      <RecommendationCreateModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onFinishModalForm={() => {
          setModalVisible(false);
        }}
      />
    </>
  )
}
