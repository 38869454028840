import React from 'react';
import { Outlet } from "react-router-dom";
import { useFirebaseInitialize } from './services/firebase/useFirebaseInitialize';
import { Localizer } from './services/localizer';

function RootApp() {
  const [isLoading, setIsLoading] = React.useState(true)

  useFirebaseInitialize();

  React.useEffect(() => {
    Localizer.updateTranslationsOnSharedPreferences()
      .then(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    isLoading ? <div></div> : <Outlet />
  );
}

export default RootApp;
