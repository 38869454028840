import styles from './styles.module.css';
import { localizer } from '../../../../services/localizer';
import { DatePicker, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Bar, BarChart, Cell, Tooltip, XAxis } from 'recharts';
import { useEffect, useState } from 'react';

const colors = ["#006693", "#FF7300", "#f72585"];

const ChartStats = ({ adminHomeData, onSetDates, onSetTopAmount }: any) => {
  const [data, setData] = useState<any[]>([])
  const { RangePicker } = DatePicker

  const navigate = useNavigate();

  const topAmountOptions = [
    { label: 'Top 5', value: 5 },
    { label: 'Top 10', value: 10 },
    { label: 'Top 15', value: 15 },
    { label: 'Top 20', value: 20 },
    { label: 'Top 25', value: 25 }
  ]

  useEffect(() => {
    setData([
      {
        name: localizer("Students"),
        id: 1,
        value: adminHomeData.totalStudents
      },
      {
        name: localizer("Mentors"),
        id: 2,
        value: adminHomeData.totalMentors
      },
      {
        name: localizer("Apprentices"),
        id: 3,
        value: adminHomeData.totalApprentices
      },
    ]);
  }, [])

  function handleBarClick(e: any) {
    if (e.id === 1) {
      navigate('/student/list');
    }
    else {
      navigate('/mentorship/list');
    }
  }

  function getComplaintByLevel(level: number) {
    return ((adminHomeData.totalStudentComplainsByLevel as [])
      .find((x: any) => x.complaintLevel === level) as any).total;
  }

  return (
    <section className={styles.chartStatsContainer}>
      <div className={styles.chartContainer}>
        <span className={styles.title}>{localizer("total number of students").toUpperCase()}</span>
        <div className={styles.chart}>
          <Chart data={data} onBarClick={handleBarClick} />
        </div>

      </div>
      <div className={styles.statsContainer}>
        <div className={styles.fromToDropdownContainer}>
          <RangePicker onChange={(values) => {
            const startDate = values?.[0]?.toISOString();
            const endDate = values?.[1]?.toISOString();
            onSetDates([startDate, endDate]);
          }} />
          <Select placeholder={localizer("Select top amount")}
            defaultValue={10}
            suffixIcon={<DownOutlined className={styles.selectArrowIcon} />}
            options={topAmountOptions}
            onChange={(value) => {
              onSetTopAmount(value);
            }}
          />
        </div>
        <div className={styles.statsCardContainer}>
          <div className={styles.statsCard} onClick={() => navigate('/mentorship/list')}>
            <span className={styles.title}>{localizer("mentorships").toUpperCase()}</span>
            <span className={styles.stat}>{adminHomeData.totalMentorships}</span>
          </div>
          <div className={styles.statsCard} onClick={() => navigate('/recommendation/list')}>
            <span className={styles.title}>{localizer("recommendations").toUpperCase()}</span>
            <span className={styles.stat}>{adminHomeData.totalRecommendations}</span>
          </div>
          <div className={styles.statsCard} onClick={() => navigate('/subject/list')}>
            <span className={styles.title}>{localizer("subjects").toUpperCase()}</span>
            <span className={styles.stat}>{adminHomeData.totalSubjects}</span>
          </div>
          <div className={styles.statsCard} onClick={() => navigate('/complaint/list')}>
            <span className={styles.title}>{localizer("students complaints").toUpperCase()}</span>
            <div className={styles.studentsComplaintsStatsContainer}>
              <div>
                <span className={`${styles.stat} ${styles.statBlue}`}>{adminHomeData.totalStudentComplains}</span>
              </div>
              <div className={styles.studentsComplaintsStats}>
                <span className={styles.studentsComplaintsStat}><span className={styles.statBlue}>{getComplaintByLevel(1)}</span> {localizer('light')}</span>
                <span className={styles.studentsComplaintsStat}><span className={styles.statBlue}>{getComplaintByLevel(2)}</span> {localizer('medium')}</span>
                <span className={styles.studentsComplaintsStat}><span className={styles.statBlue}>{getComplaintByLevel(3)}</span> {localizer('high')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}



const Chart = ({ data, onBarClick }: any) => {

  return (
    <BarChart
      width={350}
      height={300}
      data={data}
      margin={{
        top: 30,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <XAxis dataKey="name" fontSize={20} tick={{ fill: '#fff' }} />
      <Bar
        cursor={"pointer"}
        onClick={onBarClick}
        dataKey="value"
        label={{
          position: "top",
          fontSize: "1.5rem"
        }}
      >
        {data.map((_: any, index: number) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
      </Bar>
    </BarChart>
  );
}

export default ChartStats;
