import { Outlet } from "react-router-dom";
import '../AppLayout/AppLayout.css';
import { Divider } from '../../components/Divider';
import LoginHeader from '../../pages/Login/components/LoginHeader';

export function AppPublicLayout() {
  return (
    <>
      <div className="app-header">
        <LoginHeader />
        <Divider />
      </div>
      <main id="main">
        <Outlet />
      </main>
    </>
  );
}
